import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";


import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      admin_id: localStorage.getItem("admin_id")
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };
      // this.props.getTemplateList(qs.stringify(postobject));
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      axios.post(apiUrl+"template/list",qs.stringify(postobject)).then(res => {            
          this.setState({ totalRecord : res.data.records_count, templatelist: res.data});
      });

    }

   
   componentDidMount() {
      document.title = PageTitle('Templates');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      axios.post(apiUrl+"template/list",qs.stringify(postobject)).then(res => {            
        this.setState({ totalRecord : res.data.records_count, templatelist: res.data});
    });
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          axios.post(apiUrl+"template/list",qs.stringify(postObject)).then(res => {            
            this.setState({ totalRecord : res.data.records_count, templatelist: res.data},()=>{

            });
        });
        
    }


deletetemplate(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          email_id  : id,
          admin_id  : admin_id
        };

    axios.post(apiUrl+"template/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
          
              this.setState({ templatelist: res.data});
       
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      });

}
templatelist() {
    var templatelist = this.state.templatelist;
    if (templatelist != "undefined" && templatelist != null) {
      if (templatelist.status == "success") {
        if (Object.keys(templatelist).length > 0) {
          const templatelistDetails = templatelist.templatelist.map(
            (templatelist, Index) => {
              let sno = Index+1;
              return (
                <tr key={templatelist.email_id}>
                <td>{sno}</td>
                  <td>
                  {templatelist.email_name}
                  </td>
                  <td>
                   <Link to={{ pathname: '/templates-edit/'+templatelist.email_id}} className="templateedit" title="edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                   <Link onClick={this.deletetemplate.bind(this, templatelist.email_id)} className="templateedit" title="delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>

                  </td>
                </tr>
              );
            }
          );
          return templatelistDetails ;
        }else{
          return (
            <tr>
              <td colspan="3" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      } else {
       
        return (
          <tr>
            <td colspan="3" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {

      return (
        <tr>
          <td colspan="3" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="templates" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
             <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Templates</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/template/add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.templatelist()}</tbody>
                </Table>
                 <div className="text-center">
         {/*   <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />*/}
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default withRouter(List);