import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
// import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
// import { GET_COMPANYLIST, GET_DELETECOMPANY  } from '../../actions';
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      companylist:''
    };
    

     // var url = apiUrl+'company/list?id='+CompanyId;
      var qs = require('qs');

      axios.get(apiUrl+"company/list").then(res => {
          this.setState({
            companylist: res.data,
          });
      });
    }

   
   componentDidMount() {
   }


   deletecompany(id){
    
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        id  :id
      };
    // this.props.getDeleteCompany(qs.stringify(postObject));

    axios.get(apiUrl+"company/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          // this.setState({
          //   companylist: res.data.companylist,
          // });
            axios.get(apiUrl+"company/list").then(res => {
              if(res.data){
                  this.setState({
                    companylist: res.data
                  });
              }
            });
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      });
  }
  companylist() {
    var companylist = this.state.companylist;
    if (companylist != "undefined" && companylist != null) {
      if (companylist.status == "success") {
        if (Object.keys(companylist).length > 0) {
          const companylistDetails = companylist.companylist.map(
            (companylist, Index) => {
              let sno = Index+1;
              if(companylist.admin_status == 'Yes'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={companylist.admin_id}>
					<td>{sno}</td>
					<td>
					{companylist.admin_company_name}
					</td>                 
					<td>{companylist.admin_email_address}</td>
					{/* <td>{companylist.admin_contact}</td> */}
				
					<td>{companylist.admin_username}</td>
					<td>{companylist.admin_org_password}</td>
					<td>{moment(companylist.admin_created_on).format("DD-MM-YYYY hh:mm A")}</td>
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/company-edit/'+companylist.admin_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletecompany.bind(this,companylist.admin_id)} className="" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return companylistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="companylist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

        <div className="listing-header"> 
          <div className="title">
            <h3>Companies</h3>
          </div> 
          <div className="add_button">  
              <a className="add" href='/company-add'>Add</a> 
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company Name</th>               
				<th>Email</th>
				{/* <th>Contact</th> */}
				<th>Username</th>
				<th>Password</th>
        <th>Registered On</th>
               
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.companylist()}</tbody>
          </Table>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;