import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';


class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      paymentsheduledhistorylist:'',
        paymenttypelist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_type:'',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

      axios.post(apiUrl+"student/paymenthistorysheduledlist",qs.stringify(postobject)).then(res => {
        this.setState({ totalRecord :res.data.records_count, paymentsheduledhistorylist: res.data.paymenthistorysheduledlist },()=>{
          this.paymentsheduledhistorylist();
        });
      });
    }

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');

			axios.get(apiUrl+'student/getpaymentsheduledtypelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						paymenttypelist: [{value: '', label: 'Search By Payment Sheduled Type'}].concat(res.data.paymenttypelist)
					})
				
				}else{
				//console.log("test")
				}
			});

   }

   handlePageChange(pageNumber) {

     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_type: this.state.search_type,
       admin_id: admin_id,
       loggedas: loggedas
     };
     axios.post(apiUrl+"student/paymenthistorysheduledlist",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord :res.data.records_count, paymentsheduledhistorylist: res.data.paymenthistorysheduledlist },()=>{
        this.paymentsheduledhistorylist();
      });
    });
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_type: formPayload.search_type,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"student/paymenthistorysheduledlist",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord :res.data.records_count, paymentsheduledhistorylist: res.data.paymenthistorysheduledlist },()=>{
      this.paymentsheduledhistorylist();
    });
  });

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.paymentsheduledhistorylist).length > 0) {
      if (Props.paymentsheduledhistorylist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.paymentsheduledhistorylist[0].records_count, paymentsheduledhistorylist: Props.paymentsheduledhistorylist[0]["paymenthistorysheduledlist"] });
        this.paymentsheduledhistorylist();
      }
    }
     
   }

   handleClickSelecetType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    console.log(event.target.options);
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
  
    var postObject = {             
      activePage   : 1,
      search_type : key,
      admin_id: admin_id,
      loggedas: loggedas         
    };
  
    axios.post(apiUrl+"student/paymenthistorysheduledlist",qs.stringify(postObject)).then(res => {
      this.setState({ totalRecord :res.data.records_count, paymentsheduledhistorylist: res.data.paymenthistorysheduledlist },()=>{
        this.paymentsheduledhistorylist();
      });
    });
  
    this.setState({
        Loading:true     
    })
  
  }

  settledAmount = (payment_id) =>{
    var admin_id = localStorage.getItem('admin_id');
    let activePage = this.state.activePage != undefined ? this.state.activePage : 1;
    axios.get(apiUrl+'student/companySetteled?payment_id='+payment_id+'&admin_id='+admin_id+'&activePage='+activePage)
    
    .then(res => {
     
      if(res.data.status == 'success'){
        
        this.setState({
          paymentsheduledhistorylist: res.data.paymentsheduledhistorylist
        });
        $('.success_message').html('<div class="status_sucess"><h3>Payment settled status changed successfully</h3></div>');
        setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        3000
        );
      
      }else{
      //console.log("test")
      }
    });
  }

  paymentsheduledhistorylist() {
    var paymentsheduledhistorylist = this.state.paymentsheduledhistorylist;
    console.log(paymentsheduledhistorylist,"paymentsheduledhistorylist");
    if (paymentsheduledhistorylist != "undefined" && paymentsheduledhistorylist != null) {
        if (Object.keys(paymentsheduledhistorylist).length > 0) {
          const helperlistDetails = paymentsheduledhistorylist.map(
            (paymentsheduledhistorylist, Index) => {

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymentsheduledhistorylist.paid_status;
            var payment_type = paymentsheduledhistorylist.payment_type == "onetime" ? "One Time" : paymentsheduledhistorylist.payment_type;
            var payment_plan = paymentsheduledhistorylist.payment_type == "onetime" ?  paymentsheduledhistorylist.payment_plan = 'N/A' : paymentsheduledhistorylist.payment_plan;

            var username = paymentsheduledhistorylist.anonymous_type == "yes" ?  paymentsheduledhistorylist.username = 'Anonymous' : paymentsheduledhistorylist.username;
            var email = paymentsheduledhistorylist.anonymous_type == "yes" ?  paymentsheduledhistorylist.email = 'Anonymous' : paymentsheduledhistorylist.email;
            var mobileno = paymentsheduledhistorylist.anonymous_type == "yes" ?  paymentsheduledhistorylist.mobileno = 'Anonymous' : paymentsheduledhistorylist.mobileno;

            var name = paymentsheduledhistorylist.name == null ?  paymentsheduledhistorylist.name = 'N/A' : paymentsheduledhistorylist.name;
            var u_name = paymentsheduledhistorylist.u_name == null ?  paymentsheduledhistorylist.u_name = 'N/A' : paymentsheduledhistorylist.u_name;
            var kollel_name = paymentsheduledhistorylist.kollel_name == null ?  paymentsheduledhistorylist.kollel_name = 'N/A' : paymentsheduledhistorylist.kollel_name;
            var tutor_name = paymentsheduledhistorylist.tutor_name == null ?  paymentsheduledhistorylist.tutor_name = 'N/A' : paymentsheduledhistorylist.tutor_name;
            var additional_amount = paymentsheduledhistorylist.additional_amount == null ?  paymentsheduledhistorylist.additional_amount = 'N/A' : paymentsheduledhistorylist.additional_amount;
               if(additional_amount !== "N/A"){
               var additional_amount = "$"+additional_amount;
               }else{
           var additional_amount = "N/A";
               }

            if(paymentsheduledhistorylist.name != null && paymentsheduledhistorylist.payment_for == 'student'){
              name = paymentsheduledhistorylist.name+" (Avrech)";
            }else if(paymentsheduledhistorylist.kollel_name != null && paymentsheduledhistorylist.payment_for == 'kollel'){
              name = paymentsheduledhistorylist.kollel_name+" (Kollel)";
            }else{
              name = paymentsheduledhistorylist.tutor_name+" (Melamed)";
            }

            var paidTo;
            if(paymentsheduledhistorylist.behalf_of!== null && paymentsheduledhistorylist.payment_for == 'student' &&  paymentsheduledhistorylist.u_name !== null){
                paidTo = paymentsheduledhistorylist.u_name+'(behalf of '+paymentsheduledhistorylist.name+')';
            }else if(paymentsheduledhistorylist.behalf_of!== null && paymentsheduledhistorylist.payment_for == 'student' &&  paymentsheduledhistorylist.kollel_name !== null){
              console.log(paymentsheduledhistorylist.kollel_name)
               paidTo = paymentsheduledhistorylist.kollel_name+'(behalf of '+paymentsheduledhistorylist.name+')';
            }else{
               paidTo = 'N/A';
            }

            var paymenttype;
            if(payment_type && payment_type!== null && payment_type!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype =  payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

            var cmpycommissionamt;
            if(paymentsheduledhistorylist.payment_status== 'donate'){
              //paymenttype = payment_type.toUpperCase();
              cmpycommissionamt =  '$'+paymentsheduledhistorylist.original_amount;
            }else{
              cmpycommissionamt = 'N/A';
            }

             var admincommissionamt;
            if(paymentsheduledhistorylist.payment_status== 'donate'){
              //paymenttype = payment_type.toUpperCase();
              admincommissionamt =  '$'+paymentsheduledhistorylist.admin_original_amount+ '('+paymentsheduledhistorylist.commission_percentage+')%';
            }else{
              admincommissionamt = 'N/A';
            }

            var paymentstatus;
            if(paymentsheduledhistorylist.payment_status == 'donate'){
              paymentstatus = 'Donate';
            }else{
              paymentstatus = 'Special Request';
            }
         
          return (
                <tr key={paymentsheduledhistorylist.id}>
          <td>{sno}</td>
          <td>{username}</td>
          <td>{email}</td>
          <td>{mobileno}</td>
          {/*
          <td>{payment_plan}</td>*/}
          <td>{name}</td>
          <td>{moment(paymentsheduledhistorylist.created_date).format("MM/DD/YYYY hh:mm A")}</td>
          <td>${paymentsheduledhistorylist.user_pay_original_amount}</td>
          <td>{cmpycommissionamt}</td>
          <td>{admincommissionamt}</td>
          <td>{paymentstatus}</td>
          <td>{paymenttype}</td>        
          {/* <td>{paid_status.charAt(0).toUpperCase() + paid_status.slice(1)}</td> */}
          {paymentsheduledhistorylist.to_settled === 'no' ?
          <td className="actiongroup">
            <button className="settled-amt-btn" title="View" onClick={this.settledAmount.bind(this, paymentsheduledhistorylist.id)}>To be settled</button>
          </td>:<td>{paymentsheduledhistorylist.to_settled}</td>}
        </tr>
              );
            }
          );
          return helperlistDetails;
        }
    }else {
      return (
        <tr>
          <td colspan="12" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    const {paymenttypelist} = this.state;
    var current = this;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payment-sheduled" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className='success_message'></div>
                <div className="title">
                 {localStorage.getItem("loggedas") === 'admin'? <h3>Payment Management</h3>:<h3>Student Goals</h3>}
                </div>

                <div className="form-group">
                <select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
                <option data-key="">Search by Payment Type</option>
                <option data-key="onetime">One Time</option>
                <option data-key="recurring">Recurring</option>
                </select>
              <div className="errorassign_paymenttype"></div>
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Supporter</th>
                  <th>Supporter Email</th>
                  <th>Supporter Contact</th>
                 {/* <th>Payment Plan</th> */}
                  <th>Users (Type)</th>
                 {/*  <th>Yeshiva</th> */}
                  <th>Paid On</th>
                  <th>Supported Amount</th>
                  <th>Paid Amount</th>
                  <th>Admin Commission</th>
                  <th>Type</th>
                  <th>Payment Type</th>
                  {/* <th>Payment Status</th> */}
                  <th>Settled Status</th>
                   </tr>
                  </thead>
                  <tbody>{this.paymentsheduledhistorylist()}</tbody>
                </Table>
                </div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;