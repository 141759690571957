import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Link, withRouter } from "react-router-dom";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl,} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
      super(props);
      this.state = {
        studentlist:'',
        domainlist:[],
        universitynamelist:[],
        companylist:[],
      };
      
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      search_domain:'',
      search_university:'',
      search_company: '',
      admin_id: admin_id,
      loggedas: loggedas
      };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
     
      axios.post(apiUrl+"student/list",qs.stringify(postobject)).then(res => {
         
          this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
      });

    }

    checkstatus(id, status){
      let stat = ''
        if(status == 'Inactive'){
          stat = "A";
        }else{
          stat = "I";
        }
        $('.statusmessage').html('<span>Please wait...</span>');
            var qs = require('qs');
            var postObject = {
              admin_id : localStorage.getItem("admin_id"),
              loggedas : localStorage.getItem("loggedas"),
              student_id  :id,
              status: stat
            };          
          axios.post(apiUrl+"student/change_status_student",qs.stringify(postObject)).then(res => {
              this.setState({ totalRecord : res.data.records_count, studentlist: res.data},()=>{
                $('.statusmessage').html('');
              });
          });
    }

    inactivatestudent(id, status){
      confirmAlert({
       customUI: ({ onClose }) => {
         return (
           <div className='custom-ui'>
             <h1>Are you sure, Do you want to disapprove the account?</h1>
             <button onClick={onClose}>No</button>
             <button
               onClick={() => {
                  this.checkstatus(id, status)
                  onClose();
               }}
             >
               Yes
             </button>
           </div>
         );
       }
     });
     }

    activatestudent(id, status){
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure, Do you want to approve the account?</h1>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.checkstatus(id, status);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
    }

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'student/getdomainlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						domainlist: [{value: '', label: 'Sort by Domain'}].concat(res.data.domainlist)
					})
				
				}else{
				//console.log("test")
				}
			});

      var admin_id = localStorage.getItem('admin_id');
			// axios.get(apiUrl+'student/getuniversitynamelist?admin_id='+admin_id)
			axios.get(apiUrl+'student/getkollelnamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						universitynamelist: [{value: '', label: 'Sort by Kollel'}].concat(res.data.kollelnamelist)
					})
				
				}else{
				//console.log("test")
				}
			});

      var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'student/getcompanylist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						companylist: [{value: '', label: 'Sort by Company'}].concat(res.data.companylist)
					})
				
				}else{
				//console.log("test")
				}
			});

   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_domain: this.state.search_domain,
       search_university: this.state.search_university,
       search_company : this.state.search_company,
       admin_id: admin_id,
       loggedas:loggedas
     };
     var qs = require('qs');
      axios.post(apiUrl+"student/list",qs.stringify(postobject)).then(res => {
         
          this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
      });
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_domain : formPayload.search_domain,
    search_university : formPayload.search_university,
    search_company: formPayload.search_company,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  var qs = require('qs');
  axios.post(apiUrl+"student/list",qs.stringify(postObject)).then(res => {
    
      this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
  });
} 



   componentWillReceiveProps(Props){

    console.log(Props);

    if (Object.keys(Props.studentlist).length > 0) {
      if (Props.studentlist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.studentlist[0].records_count, studentlist: Props.studentlist[0]["studentlist"] });
        this.studentlist();
      }
    }


    if(Props.changestatusstudent !== this.props.changestatusstudent){
      if (Props.changestatusstudent[0]["status"] === "success") { 
            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");
            var postobject = {
            activePage: 1,
            search_all: '',
            search_domain:'',
            search_university:'',
            search_company: '',
            admin_id: admin_id,
            loggedas: loggedas
            };
            var qs = require('qs');
              axios.post(apiUrl+"student/list",qs.stringify(postobject)).then(res => {
                
                  this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
              });
            this.setState({
             //  totalRecord : Props.changestatusstudent[0].records_count, studentlist: Props.changestatusstudent[0]["studentlist"] 
            });
           
            
            $('.statusmessage').html('Status Changed Successfully');
            window.location.reload(); 
           this.studentlist();
           this.studentlist();
      }
    }

    if (Object.keys(Props.deletestudent).length > 0) {
      if (Props.deletestudent[0]["status"] === "success") {
        this.setState({
          studentlist: Props.deletestudent[0]["studentlist"],
        });
      }

      this.setState({statusmessage: Props.deletestudent[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
     
   }

   deletestudent(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };
    
    axios.post(apiUrl+"student/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");
            var postobject = {
            activePage: 1,
            search_all: '',
            search_domain:'',
            search_university:'',
            search_company: '',
            admin_id: admin_id,
            loggedas: loggedas
            };
            axios.post(apiUrl+"student/list",qs.stringify(postobject)).then(res => {
                this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
            });

            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      });

}

handleClickSelecet = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");

  var postObject = {             
    activePage   : 1,
    search_domain : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  var qs = require('qs');
  
  axios.post(apiUrl+"student/list",qs.stringify(postObject)).then(res => {
     
      this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
  });
  this.setState({
      search_domain: key,
      Loading:true     
  })

}

handleClickSelecetUniversity = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_university : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"student/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
  });

  this.setState({
      Loading:true     
  })

}

handleClickSelecetCompany = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_company : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"student/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, studentlist: res.data});
  });

  this.setState({
      Loading:true     
  })

}

studentlist() {
    var studentlist = this.state.studentlist;
    if (studentlist != "undefined" && studentlist != null) {
      if (studentlist.status == "success") {
        if (Object.keys(studentlist).length > 0) {
          const helperlistDetails = studentlist.studentlist.map(
            (studentlist, Index) => {
              let sno = Index+1;
              if((studentlist.u_name !== '') && (studentlist.u_name !== null)){
                var u_name = studentlist.u_name;
              }else{
                  u_name = 'N/A';
              }

              if((studentlist.f_name != '') && (studentlist.f_name != null)){
                var f_name = studentlist.f_name;
              }else{
                  f_name = 'N/A';
              }

               if(studentlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var activebtn = 'no';
              if(localStorage.getItem('loggedas') === 'admin'){
                var activebtn = 'yes';
              }
              if(localStorage.getItem('loggedas') === 'kollel'){
                if(studentlist.university_name=== null && studentlist.kollel!== null){
                  var activebtn = 'yes';
                }
              }
              if(localStorage.getItem('loggedas') === 'yeshiva'){
                if(studentlist.university_name=== null && studentlist.kollel!== null){
                  var activebtn = 'no';
                }else if(studentlist.university_name!== null && studentlist.kollel!== null){
                  var activebtn = 'yes';
                }
              }

              var istyle = { width: 100};
              return (
                <tr key={studentlist.id}>
      					<td>{sno}</td>  
      					<td>{studentlist.name}</td>
               
                <td>{studentlist.email}</td>
                {/* <td>{studentlist.mobileno}</td> */}
                 <td>{studentlist.username}</td>
                {localStorage.getItem('admin_id') === '1' && <td>{studentlist.password_decode}</td> }
                {/* <td>{(studentlist.domain_name) ? studentlist.domain_name : "N/A"}</td> */}
                {/*<td>{studentlist.school_name}</td>*/}
                {/* {localStorage.getItem('loggedas') != 'kollel' &&
                <td>{u_name}</td>} */}
                {/* <td>{f_name}</td>
                <td>{studentlist.settings_site_title}</td>   */}
                <td>{moment(studentlist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{status}</td>                
                <td className="actiongroup" >
                    
                    {(localStorage.getItem('loggedas') === 'admin') || (localStorage.getItem('loggedas') === 'kollel')?
                      <> 
                    <Link to={{ pathname: '/student-view/'+studentlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/student-edit/'+studentlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                     <Link onClick={this.deletestudent.bind(this,studentlist.id)} className="" title="Duplicate"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                     </>:<></>
                    }
                    {/* {(localStorage.getItem('loggedas') !== 'admin')?
                      <> */}
                      {(activebtn ==='yes')?
                        <>
                         {(status == 'Active') ?<Link onClick={this.inactivatestudent.bind(this, studentlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activatestudent.bind(this, studentlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>} 
                         </>:<>
                        </>
                      }
                      {/* </>:
                      <> 
                      </>

                    } */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="student" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
              <div className="listing-header">
                <div className="title">
                  <h3>Avrech</h3>
                </div>
               <div className="select-1">
                { admin_id == '1'?
              <div className="form-group time-row">
                <select  onChange={current.handleClickSelecetUniversity}  value={this.state.search_university}>                    
                {this.state.universitynamelist.map((universitynamelist) => <option key={universitynamelist.value} data-key={universitynamelist.value} >{universitynamelist.label}</option>)}
                </select>
              <div className="errorassign_university"></div>
              </div> : ''}
              </div>
              <div className="select-2">
                 {admin_id == '1'?	
              <div className="form-group time-row">
                <select  onChange={current.handleClickSelecetCompany}  value={this.state.search_company}>                    
                {this.state.companylist.map((companylist) => <option key={companylist.value} data-key={companylist.value} >{companylist.label}</option>)}
                </select>
              <div className="errorassign_comapny"></div>
              </div> : ''}
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {localStorage.getItem('loggedas') === 'admin' && <div className="add_button">  
                    <a className="add" href='/student-add'>Add</a>
                </div>}
                {localStorage.getItem('loggedas') === 'kollel' && <div className="add_button">  
                    <a className="add" href='/student-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/student-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a>
                </div>}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Avrech Name</th>
                
                  <th>Email</th>
                  {/* <th>Contact</th> */}
                    <th>Username</th>
                  {localStorage.getItem('admin_id') === '1' && <th>Password</th> }
                  {/* <th>Domain</th> */}
                  {/*<th>Kollel</th>*/}
                  {/* {localStorage.getItem('loggedas') != 'kollel' &&
                  <th>Yeshiva</th>} */}
                  <th>Registered On</th>
                  {/* <th>Funding</th>
                  <th>Companies</th> */}
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.studentlist()}</tbody>
                </Table>
				</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;