import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png";
import $, { data } from 'jquery';
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }

	menuclick = ()=>{
		if($('.sidebar-menu').hasClass('menu-close'))
		{
			$('.sidebar-menu').removeClass('menu-close');
			
		}else{
			$('.sidebar-menu').addClass('menu-close');    
			
		}
	}
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" onClick={this.menuclick.bind(this)} className="nav-link close-navbar mb-side-nav" ><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
    {localStorage.getItem('loggedas') === 'admin' &&
			   <>
    
      <li className={this.activeMenu('dashboard')}>
        <a title="Dashboard" href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>

      <li className={this.activeMenu('user')}>
      <a title="Users" href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users / Supporters</span>
        </a>
      </li>

      <li className={this.activeMenu('companylist')}>
      <a title="Company" href="/company">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Companies</span>
        </a>
      </li>
      
      {localStorage.getItem('loggedas') === 'admin' &&
					<li className={this.activeMenu('student')}>
						<a title="Student" href="/student">
              <i className="ft-users"></i>
							<span>Avrech</span>
						</a>
					</li>
  				}

       			 {/* {localStorage.getItem('loggedas') === 'admin' &&
					<li className={this.activeMenu('tutor')}>
						<a title="Tutor" href="/tutor">
            <i className="ft-users"></i>
							<span>Melamed</span>
						</a>
					</li>} */}
				{/* {localStorage.getItem('loggedas') === 'admin' &&
					<li className={this.activeMenu('talmid')}>
						<a title="Talmid" href="/talmid">
							<i className="talmid-icon icon" aria-hidden="true"></i>
							<span>Talmid</span>
						</a>
					</li>
  				} */}

        <li className={this.activeMenu('kollel')}>
					<a title="Kollel" href="/kollel">
            <i className="ft-users"></i>
						<span>Kollel</span>
					</a>
				</li>

				<li className={this.activeMenu('shules')}>
					<a title="Shul" href="/shules">
         			 <i className="ft-users"></i>
						<span>Shules</span>
					</a>
				</li>

        <li className={this.activeMenu('compaign')}>
					<a title="Campaign" href="/compaign">
          <i className="ft-users"></i>
						<span>Campaign</span>
					</a>
				</li>

        		<li className={this.activeMenu('dashboardmanagement')}>
					<a title="Dashboard Management Builder" href="/dashboardmanagement">
					<i className="ft-server"></i>
					<span className="menu-title" data-i18n="" >Dashboard Management</span>
					</a>
				</li>
				<li className={this.activeMenu('offers')}>
					<a title="Dashboard Management Builder" href="/offers">
					<i className="ft-server"></i>
					<span className="menu-title" data-i18n="" >Offers</span>
					</a>
				</li>

				<li className={this.activeMenu('awards')}>
					<a title="Dashboard Management Builder" href="/awards">
					<i className="ft-server"></i>
					<span className="menu-title" data-i18n="" >Award Management</span>
					</a>
				</li>

				<li className={this.activeMenu('advertisementCategory')}>
					<a title="Dashboard Management Builder" href="/advertisementCategory">
					<i className="ft-server"></i>
					<span className="menu-title" data-i18n="" >Advertisement Category</span>
					</a>
				</li>

				<li className={this.activeMenu('advertisement')}>
					<a title="Dashboard Management Builder" href="/advertisement">
					<i className="ft-server"></i>
					<span className="menu-title" data-i18n="" >Advertisement</span>
					</a>
				</li>

				<li className={this.activeMenu('landing_cms')}>
					<a title="CMS" href="/cms">
						<i className="ft-edit icon" aria-hidden="true"></i>
						<span>Plugin Content</span>
					</a>
				</li>

        <li className={this.activeMenu('payment-history')}>
					<a title="Payment History" href="/payment-history">
						<i className="ft-faq icon" aria-hidden="true"></i>
						<span>Payment History </span>
					</a>
				</li>
				<li className={this.activeMenu('payment-sheduled')}>
					<a title="Payment History" href="/payment-sheduled">
						<i className="ft-membership icon" aria-hidden="true"></i>
						<span>Payment Sheduled</span>
					</a>
				</li>

			
				<li className={this.activeMenu('donationcategory')}>
					<a href="/donationcategory">
					<i className="ft-membership icon" aria-hidden="true"></i>
					<span className="menu-title" data-i18n="">Donation Category</span>
					</a>
				</li>

				<li className={this.activeMenu('donation')}>
					<a href="/donation">
					<i className="ft-membership icon"></i>
					<span className="menu-title" data-i18n="">Donation</span>
					</a>
				</li>

				<li className={this.activeMenu('subscribers')}>
					<a href="/subscribers">
					<i className="ft-membership icon"></i>
					<span className="menu-title" data-i18n="">Subscribers</span>
					</a>
				</li>

				<li className={this.activeMenu('package')}>
					<a href="/package">
					<i className="ft-briefcase icon"></i>
					<span className="menu-title" data-i18n="">Package</span>
					</a>
				</li>

        {this.state.admin_id == 1 ?
				<li className={this.activeMenu('fonts')}>
					<a title="Fonts" href="/fonts">
						<i className="ft-type" aria-hidden="true"></i>
						<span>Fonts</span>
					</a>
				</li> :''} 
			{this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
					<a title="Templates" href="/templates">
						<i className="ft-template" aria-hidden="true"></i>
						<span>Email Templates</span>
					</a>
				
				</li>:''} 
				<li className={this.activeMenu('setting')}>
					<a title="Settings" href="/setting">
						<i className="ft-settings icon" aria-hidden="true"></i>
						<span>Settings</span>
					</a>
				</li> </>}

        {localStorage.getItem('loggedas') === 'yeshiva' &&
			     <>
					<li className={this.activeMenu('dashboard')}>
						<a title="Dashboard" href="/dashboard">
							<i className="dashboard-icon icon"></i><span>Dashboard</span>
						</a>
					</li>	
					<li className={this.activeMenu('student')}>
						<a title="Student" href="/student">
							<i className="studentlist-icon icon" aria-hidden="true"></i>
							<span>Avrech</span>
						</a>
					</li>
					<li className={this.activeMenu('yehsivapayment-history')}>
						<a title="My Goals" href="/yeshivapayment-history">
							<i className="history-icon icon" aria-hidden="true"></i>
							<span>My Goals </span>
						</a>
					</li> 
					<li className={this.activeMenu('payment-history')}>
						<a title="Student Goals" href="/payment-history">
							<i className="history-icon icon" aria-hidden="true"></i>
							<span>Student Goals </span>
						</a>
					</li> 
					<li className={this.activeMenu('setting')}>
						<a title="Settings" href="/yeshiva_setting">
							<i className="setting-icon icon" aria-hidden="true"></i>
							<span>Settings</span>
						</a>
					</li> 
				</>}

        {localStorage.getItem('loggedas') === 'kollel' &&
			     <>
					<li className={this.activeMenu('dashboard')}>
						<a title="Dashboard" href="/dashboard">
							<i className="dashboard-icon icon"></i><span>Dashboard</span>
						</a>
					</li>	
				
					<li className={this.activeMenu('student')}>
						<a title="KollelStudent" href="/student">
							<i className="studentlist-icon icon" aria-hidden="true"></i>
							<span>Avrech</span>
						</a>
					</li>
  				
					<li className={this.activeMenu('kollelpayment-history')}>
						<a title="My Goals" href="/kollelpayment-history">
							<i className="history-icon icon" aria-hidden="true"></i>
							<span>My Goals </span>
						</a>
					</li> 
					<li className={this.activeMenu('payment-history')}>
						<a title="Payment History" href="/payment-history">
							<i className="history-icon icon" aria-hidden="true"></i>
							<span>Student Goals </span>
						</a>
					</li> 
					<li className={this.activeMenu('setting')}>
						<a title="Settings" href="/kollel_setting">
							<i className="setting-icon icon" aria-hidden="true"></i>
							<span>Settings</span>
						</a>
					</li> 
				</>}
      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;