import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';


import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

// import { GET_DASHBOARDLIST  } from '../../actions';

import axios from 'axios';
import { apiUrl } from '../Config/Config';
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:''
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };


    axios.post(apiUrl+"dashboard/list",qs.stringify(postobject)).then(res => {
			if(res.data.results){
			
        this.setState({ 
          totalRecord : res.data.records_count_students, 
          totalRecordUsers : res.data.records_count_users,
          totalRecordCompanies :res.data.records_count_companies,
          totalRecordUniversities : res.data.records_count_universities,
          totalRecordStudentGoals : res.data.records_count_payment,
          totalRecordMyGoals : res.data.yeshiva_count_payment,
          });
			}
		});

    // this.props.getDashboardList(qs.stringify(postobject));

    }

   
    componentDidMount() {
   }

   

  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>

              <div className="car-listing-wrap leaa-admin-head card dashboard-wrap">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>


                  <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue">{this.state.totalRecordUsers}59</h4>
                            </div>
                              <a href="user" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Supporters<i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                      {localStorage.getItem('loggedas') === 'admin' ? 
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecord}51</h4>
                             </div>
                              <a href="student" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Avrech <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> : <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecordStudentGoals}</h4>
                             </div>
                              <a href="student" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Student Goals <i className="ft-membership float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> }
            				</div>

                    <div className="form-row ">
                    {localStorage.getItem('loggedas') === 'admin' ?
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-purple">{this.state.totalRecordCompanies}3</h4>
                            </div>
                              <a href="company" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Companies<i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>:
                      <div className="form-left">
            					
                      <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge card-title-purple">{this.state.totalRecordMyGoals}</h4>
                          </div>
                            <a href="company" className="">
                              <div className="card-content collapse show">
                                  <div className="card-body p-1">
                                      <h4 className="font-large-1 text-bold-400">My Goals<i className="ft-users float-right"></i></h4>
                                  </div>
                                  <div className="card-footer p-1">
                                  </div>
                              </div>
                        </a>
                      </div>   
                    </div>
                      }
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-red">12</h4>
                             </div>
                              <a href="kollel" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Kollel <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>

                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-yellow">7</h4>
                            </div>
                              <a href="shules" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Shules<i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-orange">14</h4>
                             </div>
                              <a href="compaign" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Campaign <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>
                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue"></h4>
                            </div>
                              <a href="cms" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Plugin Content<i className="ft-edit icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black"></h4>
                             </div>
                              <a href="payment-history" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Payment History <i className="ft-membership icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>
                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-red"></h4>
                            </div>
                              <a href="donation" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Donation<i className="ft-membership icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-yellow"></h4>
                             </div>
                              <a href="donationcategory" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Donation Category <i className="ft-membership icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>

                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-purple"></h4>
                            </div>
                              <a href="package" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Package<i className="ft-briefcase icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-orange"></h4>
                             </div>
                              <a href="subscribers" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Subscribers <i className="ft-membership icon float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>

                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue"></h4>
                            </div>
                              <a href="fonts" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Fonts<i className="ft-type float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black"></h4>
                             </div>
                              <a href="templates" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Email Templates <i className="ft-template float-right"></i></h4>

                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>


                </div>
          </div>  
      </div>      
    </div>
    );
  }
}

// const mapStateTopProps = (state) => {
//   return {
//     dashboardlist: state.dashboardlist,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getDashboardList: (formVal) =>{
//       dispatch({ type: GET_DASHBOARDLIST, formVal});
//    },
   
//   }
// }
export default List;