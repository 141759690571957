import React, { Component } from 'react';
// import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
// import { GET_VIEWUSERLIST } from '../../actions';

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      userid: userid,
      userlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :userid
    };
    // this.props.getViewUserList(qs.stringify(postObject));  
    axios.post(apiUrl+"user/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        
          this.setState({userlistview:res.data.userlistview[0]}) 
        
      }	
		});
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.userlistview!==this.state.userlistview){
        if(Props.userlistview[0].status == "success"){
          this.setState({userlistview: Props.userlistview[0].userlistview[0]}) 
        }
      }
   }


  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Supporter Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.firstname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.lastname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Mobile number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.mobileno}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.user_type}</p>
                          </div>
                          
                        </div>
                    </div>
                  </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    </div>
    );
  }
}

export default withRouter(View);