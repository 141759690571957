import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';


class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
        paymenthistorylist:'',
        paymenttypelist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_type:'',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

      axios.post(apiUrl+"student/paymenthistorylist",qs.stringify(postobject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, paymenthistorylist: res.data },()=>{
          this.paymenthistorylist();
        });
      });

    }

   
    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');

			axios.get(apiUrl+'student/getpaymenttypelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						paymenttypelist: [{value: '', label: 'Search By Payment Type'}].concat(res.data.paymenttypelist)
					})
				
				}else{
				//console.log("test")
				}
			});

   }

   handlePageChange(pageNumber) {

     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_type: this.state.search_type,
       admin_id: admin_id,
       loggedas: loggedas
     };
     axios.post(apiUrl+"student/paymenthistorylist",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, paymenthistorylist: res.data },()=>{
        this.paymenthistorylist();
      });
    });
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_type: formPayload.search_type,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"student/paymenthistorylist",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, paymenthistorylist: res.data },()=>{
      this.paymenthistorylist();
    });
  });

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.paymenthistorylist).length > 0) {
      if (Props.paymenthistorylist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.paymenthistorylist[0].records_count, paymenthistorylist: Props.paymenthistorylist[0]["paymenthistorylist"] });
        this.paymenthistorylist();
      }
    }
     
   }

   handleClickSelecetType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    console.log(event.target.options);
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
  
    var postObject = {             
      activePage   : 1,
      search_type : key,
      admin_id: admin_id,
      loggedas: loggedas         
    };
  
    axios.post(apiUrl+"student/paymenthistorylist",qs.stringify(postObject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, paymenthistorylist: res.data },()=>{
        this.paymenthistorylist();
      });
    });
  
    this.setState({
        Loading:true     
    })
  
  }
   paymenthistorylist() {
    var paymenthistorylist = this.state.paymenthistorylist;
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      if (paymenthistorylist.status == "success") {
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.paymenthistorylist.map(
            (paymenthistorylist, Index) => {

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type == "onetime" ?  paymenthistorylist.payment_plan = 'N/A' : paymenthistorylist.payment_plan;

            var username = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.username = 'Anonymous' : paymenthistorylist.username;
            var email = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.email = 'Anonymous' : paymenthistorylist.email;
            var mobileno = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.mobileno = 'Anonymous' : paymenthistorylist.mobileno;

            var name = paymenthistorylist.name == null ?  paymenthistorylist.name = 'N/A' : paymenthistorylist.name;
            var u_name = paymenthistorylist.u_name == null ?  paymenthistorylist.u_name = 'N/A' : paymenthistorylist.u_name;
            var kollel_name = paymenthistorylist.kollel_name == null ?  paymenthistorylist.kollel_name = 'N/A' : paymenthistorylist.kollel_name;
            var tutor_name = paymenthistorylist.tutor_name == null ?  paymenthistorylist.tutor_name = 'N/A' : paymenthistorylist.tutor_name;
            var additional_amount = paymenthistorylist.additional_amount == null ?  paymenthistorylist.additional_amount = 'N/A' : paymenthistorylist.additional_amount;
               if(additional_amount !== "N/A"){
               var additional_amount = "$"+additional_amount;
               }else{
           var additional_amount = "N/A";
               }

            if(paymenthistorylist.name != null && paymenthistorylist.payment_for == 'student'){
               name = paymenthistorylist.name+" (Avrech)";
            }else if(paymenthistorylist.kollel_name != null && paymenthistorylist.payment_for == 'kollel'){
              name = paymenthistorylist.kollel_name+" (Kollel)";
            }else if(paymenthistorylist.compaign_name != null && paymenthistorylist.payment_for == 'campaign'){
              name = paymenthistorylist.compaign_name+" (Campaign)";
            }else if(paymenthistorylist.shules_name != null && paymenthistorylist.payment_for == 'shules'){
              name = paymenthistorylist.shules_name+" (Shules)";
            }else{
              name = paymenthistorylist.tutor_name+" (Melamed)";
            }

            var paidTo;
            if(paymenthistorylist.behalf_of!== null && paymenthistorylist.payment_for == 'student' &&  paymenthistorylist.u_name !== null){
              console.log(paymenthistorylist.u_name)
                paidTo = paymenthistorylist.u_name+'(behalf of '+paymenthistorylist.name+')';
            }else if(paymenthistorylist.behalf_of!== null && paymenthistorylist.payment_for == 'student' &&  paymenthistorylist.kollel_name !== null){
              console.log(paymenthistorylist.kollel_name)
               paidTo = paymenthistorylist.kollel_name+'(behalf of '+paymenthistorylist.name+')';
            }else if(paymenthistorylist.behalf_of!== null && paymenthistorylist.payment_for == 'campaign' &&  paymenthistorylist.compaign_name !== null){
              console.log(paymenthistorylist.compaign_name)
               paidTo = 'Company (behalf of '+paymenthistorylist.compaign_name+')';
            }else if(paymenthistorylist.behalf_of!== null && paymenthistorylist.payment_for == 'shules' &&  paymenthistorylist.shules_name !== null){
              console.log(paymenthistorylist.shules_name)
               paidTo = 'Company (behalf of '+paymenthistorylist.shules_name+')';
            }else{
               paidTo = 'N/A';
            }

            var paymenttype;
            if(payment_type && payment_type!== null && payment_type!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype =  payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

            var cmpycommissionamt;
            if(paymenthistorylist.payment_status== 'donate'){
              //paymenttype = payment_type.toUpperCase();
              cmpycommissionamt =  '$'+paymenthistorylist.original_amount;
            }else{
              cmpycommissionamt = 'N/A';
            }

             var admincommissionamt;
            if(paymenthistorylist.payment_status== 'donate'){
              //paymenttype = payment_type.toUpperCase();
              admincommissionamt =  '$'+paymenthistorylist.admin_original_amount+ '('+paymenthistorylist.commission_percentage+')%';
            }else{
              admincommissionamt = 'N/A';
            }

            var paymentstatus;
            if(paymenthistorylist.payment_status == 'donate'){
              paymentstatus = 'Donate';
            }else{
              paymentstatus = 'Special Request';
            }
         
          return (
                <tr key={paymenthistorylist.id}>
          <td>{sno}</td>
          <td>{username}</td>
          <td>{email}</td>
          <td>{mobileno}</td>
          {/*
          <td>{payment_plan}</td>*/}
          <td>{name}</td>
          {/* <td>{u_name}</td> */}
          <td>{paidTo}</td>
          <td>${paymenthistorylist.user_pay_original_amount}</td>
          <td>{cmpycommissionamt}</td>
          <td>{admincommissionamt}</td>
          <td>{paymentstatus}</td>
          <td>{paymenttype}</td>        
          <td>{moment(paymenthistorylist.created_date).format("MM/DD/YYYY hh:mm A")}</td> {/*paid_status.charAt(0).toUpperCase() + paid_status.slice(1)*/}
          <td className="actiongroup">
                    <Link to={{ pathname: '/payment-view/'+paymenthistorylist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="12" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    const {paymenttypelist} = this.state;
    var current = this;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payment-history" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className="title">
                 {localStorage.getItem("loggedas") === 'admin'? <h3>Payment History</h3>:<h3>Student Goals</h3>}
                </div>

                <div className="form-group">
                <select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
                <option data-key="">Search by Payment Type</option>
                <option data-key="onetime">One Time</option>
                <option data-key="recurring">Recurring</option>
                </select>
              <div className="errorassign_paymenttype"></div>
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Supporter</th>
                  <th>Supporter Email</th>
                  <th>Supporter Contact</th>
                 {/* <th>Payment Plan</th> */}
                  <th>Users (Type)</th>
                 {/*  <th>Yeshiva</th> */}
                  <th>Paid To</th>
                  <th>Supported Amount</th>
                  <th>Company Commission</th>
                  <th>Admin Commission</th>
                  <th>Type</th>
                  <th>Payment Type</th>
                  <th>Payment On</th>
                  <th>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.paymenthistorylist()}</tbody>
                </Table>
                </div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default List;