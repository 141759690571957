import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl,baseUrl } from '../Config/Config';
import moment from 'moment';
import axios from 'axios';
class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          userlist:'',
          editable: false,
          priorityid:'',
          priorityvalue:'',
          activePage: 1,
          totalRecord: '',
          search_all:''
      };    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
   componentDidMount() {
    this.listloadedData();
   }
  listloadedData(postobject='') {
    var qs = require('qs');
     if(postobject!=''){
      var postobject = postobject
     }else{
      var postobject = {
        activePage: this.state.activePage,
        search_all: '',
        admin_id: localStorage.getItem('admin_id')  
        };
     }
    
   axios.post(apiUrl+"user/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){

         this.setState({  userlist: res.data, totalRecord: res.data.records_count}, function() {
           this.userlist();
         });
     }else{
      this.setState({  userlist: '', totalRecord: ''}, function() {
        this.userlist();
      });
     }
   }); 
  }

  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.listloadedData(postobject);
  }

  handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
    [name]: value
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject);
      
  } 


deleteuser(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id
        };

        axios.post(apiUrl+"user/delete",qs.stringify(postObject)).then(res => {
     
          if(res.data.status === 'success'){     
            this.listloadedData()
          }else{
            this.listloadedData()
           }
        }); 
}

activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id,
		      user_status: stat
        };

        axios.post(apiUrl+"user/change_status",qs.stringify(postObject)).then(res => {
     
          if(res.data.status === 'success'){     
            this.listloadedData()
          }else{
            this.listloadedData()
           }
        }); 
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => {     
          if(res.data.status === 'success'){     
              this.setState({  userlist: res.data, totalRecord: res.data.records_count}, function() {
                this.userlist();
              });
          }else{
            this.setState({  userlist: '', totalRecord: ''}, function() {
              this.userlist();
            });
           }
        });
        // this.listloadedData()
}
  userlist() {
    var userlist = this.state.userlist;
    if (userlist != "undefined" && userlist != null) {
      if (userlist.status == "success") {
        if (Object.keys(userlist).length > 0) {
          const userlistDetails = userlist.userlist.map(
            (userlist, Index) => {
              let sno = Index+1;
              if(userlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={userlist.id}>
                <td>{sno}</td>
				
                  <td>
                  {userlist.firstname}
                  </td>
                  <td>{userlist.email}</td>
                  <td>
                  {userlist.username}
                  </td>
                  <td>
                  {userlist.org_password}
                  </td>
				          {/* <td>{userlist.mobileno}</td> */}
                  <td>{userlist.shules_name != null ? userlist.shules_name : 'NA'}</td>
                  <td>{userlist.user_type}</td>
				         {/* <td>{userlist.domain_name}</td> */}
                 <td>{moment(userlist.created_on).format("DD-MM-YYYY hh:mm A")}</td>
                  <td>{status}</td>
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/view/'+userlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/edit/'+userlist.id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                     <Link onClick={this.deleteuser.bind(this,userlist.id)} className="" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                     {(status == 'Active'?<Link onClick={this.activateuser.bind(this, userlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, userlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}          
                  </td>
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="user" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

        <div className="listing-header"> 
          <div className="title">
            <h3>Users / Supporters</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/user-add'>Add &nbsp;|&nbsp;</a>
			  <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			         
                <th>Name</th>
                <th>Email</th>
                <th>User Name</th>
				        <th>Password</th>
                <th>Created By Shules</th>
               <th>User Type</th>
               <th>Registered On</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.userlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}


export default List;