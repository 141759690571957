import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      shuleslist:'',
      domainlist:[],
      companylist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_domain:'',
    search_university:'',
    search_company: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    axios.post(apiUrl+"shules/list",qs.stringify(postobject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
    });
    }

    checkstatus(id, status){
      let stat = ''
      if(status == 'Inactive'){
        stat = "A";
      }else{
        stat = "I";
      }
      $('.statusmessage').html('<span>Please wait...</span>');
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        loggedas : localStorage.getItem("loggedas"),
        shules_id  :id,
        status: stat
      };
      axios.post(apiUrl+"shules/change_status_Shules",qs.stringify(postObject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, shuleslist: res.data},()=>{
          $('.statusmessage').html('');
        });
    });
      setTimeout(()=>{
        var admin_id = localStorage.getItem("admin_id");
        var loggedas = localStorage.getItem("loggedas");
        var postobject1 = {
          activePage: 1,
          search_all: '',
          search_domain:'',
          search_university:'',
          search_company: '',
          admin_id: admin_id,
          loggedas: loggedas
        };
        axios.post(apiUrl+"shules/list",qs.stringify(postobject1)).then(res => {
          this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
      });
        $('.statusmessage').html('');
      },1000);
    }

    inactivateshules(id, status){
      confirmAlert({
       customUI: ({ onClose }) => {
         return (
           <div className='custom-ui'>
             <h1>Are you sure, Do you want to inactive the account?</h1>
             <button onClick={onClose}>No</button>
             <button
               onClick={() => {
                  this.checkstatus(id, status)
                  onClose();
               }}
             >
               Yes
             </button>
           </div>
         );
       }
     });
     }

     activateshules(id, status){
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure, Do you want to active the account?</h1>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.checkstatus(id, status);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
    }

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'student/getcompanylist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

				
				
					this.setState({
						companylist: [{value: '', label: 'Sort by Company'}].concat(res.data.companylist)
					})
				
				}else{
				//console.log("test")
				}
			});

   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_domain: this.state.search_domain,
       search_university: this.state.search_university,
       search_company : this.state.search_company,
       admin_id: admin_id,
       loggedas:loggedas
     };
     axios.post(apiUrl+"shules/list",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
  });
   }

   handleInputChange = (event) => {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_domain : formPayload.search_domain,
    search_university : formPayload.search_university,
    search_company: formPayload.search_company,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  //console.log(postObject);
  axios.post(apiUrl+"shules/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
});

} 



   

   confirmdeleteshules(id){
    confirmAlert({
     customUI: ({ onClose }) => {
       return (
         <div className='custom-ui'>
           <h1>Are you sure, Do you want to delete the account?</h1>
           <button onClick={onClose}>No</button>
           <button
             onClick={() => {
                this.deleteshules(id)
                onClose();
             }}
           >
             Yes
           </button>
         </div>
       );
     }
   });
   }

   deleteshules(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };
    axios.post(apiUrl+"shules/delete",qs.stringify(postObject)).then(res => {
    });
    setTimeout(()=>{
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject1 = {
        activePage: 1,
        search_all: '',
        search_domain:'',
        search_university:'',
        search_company: '',
        admin_id: admin_id,
        loggedas: loggedas
      };
      axios.post(apiUrl+"shules/list",qs.stringify(postobject1)).then(res => {
        this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
      });
      $('.statusmessage').html('');
    },1000);
  }

handleClickSelecet = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");

  var postObject = {             
    activePage   : 1,
    search_domain : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"shules/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
});

  this.setState({
      search_domain: key,
      Loading:true     
  })

}

handleClickSelecetUniversity = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_university : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"shules/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
});

  this.setState({
      Loading:true     
  })

}

handleClickSelecetCompany = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_company : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"shules/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, shuleslist: res.data});
});

  this.setState({
      Loading:true     
  })

}

shuleslist() {
    var shuleslist = this.state.shuleslist;
    if (shuleslist != "undefined" && shuleslist != null) {
      if (shuleslist.status == "success") {
        if (Object.keys(shuleslist).length > 0) {
          const helperlistDetails = shuleslist.shuleslist.map(
            (shuleslist, Index) => {
              let sno = Index+1;
              if((shuleslist.name !== '') && (shuleslist.name !== null)){
                var shules_name = shuleslist.shules_name;
              }else{
                shules_name = 'N/A';
              }

              if((shuleslist.username !== '') && (shuleslist.username !== null)){
                var shules_username = shuleslist.username;
              }else{
                shules_username = 'N/A';
              }

              if(shuleslist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var activebtn = 'no';
              if(localStorage.getItem('loggedas') === 'admin'){
                var activebtn = 'yes';
              }

              var istyle = { width: 100};
              console.log(typeof shuleslist.goal_amount,"shuleslist.goal_amount");
              return (
                <tr key={shuleslist.id}>
      					<td>{sno}</td>  
      					<td>{shules_name}</td>
                <td>{shuleslist.email !== '' && shuleslist.email !== null ? shuleslist.email : 'N/A'}</td>
               
                <td>{shules_username}</td>
                <td>{shuleslist.org_password}</td>
                {/* <td>{shuleslist.mobileno !== '' && shuleslist.mobileno !== null ? shuleslist.mobileno : 'N/A'}</td> */}
                {/* <td>${shuleslist.goal_limit_amount !== null && shuleslist.goal_limit_amount !== '' && shuleslist.goal_limit_amount !== '0' ? shuleslist.goal_limit_amount : '0'}</td>
                <td>${shuleslist.goal_amount !== null && shuleslist.goal_amount !== '' && shuleslist.goal_amount !== '0' ? shuleslist.goal_amount : '0'}</td>
                <td>${shuleslist.achieved_amount !== null && shuleslist.achieved_amount !== '' && shuleslist.achieved_amount !== '0' ? shuleslist.achieved_amount : '0'}</td> */}
                <td>{moment(shuleslist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{status}</td>                
                <td className="actiongroup" >
                    
                    {(localStorage.getItem('loggedas') === 'admin') ?
                      <> 
                    <Link to={{ pathname: '/add-supporter/'+shuleslist.id}} className="" title="Add Supporter">  <i className="fa fa-plus" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/shules-view/'+shuleslist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/shules-edit/'+shuleslist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.confirmdeleteshules.bind(this, shuleslist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                     </>:<></>
                    }

                    {(activebtn ==='yes')?
                      <>
                        {(status == 'Active') ?<Link onClick={this.inactivateshules.bind(this, shuleslist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateshules.bind(this, shuleslist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>} 
                        </>:<>
                      </>
                    }
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

	  var current = this;
    var admin_id = localStorage.getItem('admin_id');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="shules" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
              <div className="listing-header">
                <div className="title">
                  <h3>Shules</h3>
                </div>
               <div className="select-1">
              </div>
              <div className="select-2">
                 {admin_id == '1'?	
              <div className="form-group">
                <select  onChange={current.handleClickSelecetCompany}  value={this.state.search_company}>                    
                {this.state.companylist.map((companylist) => <option key={companylist.value} data-key={companylist.value} >{companylist.label}</option>)}
                </select>
              <div className="errorassign_comapny"></div>
              </div> : ''}
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {localStorage.getItem('loggedas') === 'admin' && <div className="add_button">  
                    <a className="add" href='/shules-add'>Add</a>
                </div>}
                {/*localStorage.getItem('loggedas') === 'kollel' && <div className="add_button">  
                    <a className="add" href='/shules-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/shules-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a>
                  </div>*/}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Shules Name</th>
                  <th>Email</th>
                  <th>User Name</th>
                  <th>Password</th>
                 
                  {/* <th>Contact</th> */}
                  {/* <th>Goal Amount</th>
                  <th>Expected Goal</th>
                  <th>Acheived Amount</th> */}
                  <th>Created On</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.shuleslist()}</tbody>
                </Table>
				</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;