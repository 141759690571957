import React, { Component } from 'react';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const studentId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
	  	this.state = {
           // domain_id:'',
            studentId: studentId,
			name:'',
			username:'',			
			age:'',
			password:'',
			changepassword:'',
			user_confirm_password: '',
			new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
			university_name:'',
			school_name:'',
			email:'',
			mobileno:'',
			dob:'',
			dob_change:'',
			donation_start_date:'',
			donation_end_date:'',
			parent_name:'',
			parent_mobileno:'',
			address:'',
			student_story:'',
			amount:'',
			donation_limit_amount:'',
			status:'',
			selectedFund:'',
			fundvalue:'',
			fundlist:[],
			selectedUniversity:'',
			universityvalue:'',
			universitylist:[],
			selectedvalue: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			kollellist:[],
			selectedKollel:'',
			kollelvalue:'',
			share_content:'',
			selectedvideoOption:'',
			domainlist:[],
			selecteddomain:'',
			selecteddomain_value:'',
			no_of_kids:'',
			learning_hours:'',
			tutor_account: 'No'

		};
		// this.props.getStudentDetailList(studentId);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
        this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");

		axios.get(apiUrl+"student/listdetail?id="+studentId).then(res => {
			if(res.data.status === "success"){

				const formdata = res.data.studentlist;

				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}

				this.setState({name:formdata.name});
				this.setState({username:formdata.username});						
				this.setState({age: formdata.age});						
				this.setState({university_name: formdata.university_name});
				this.setState({email: formdata.email});
				this.setState({school_name: formdata.school_name});
				this.setState({mobileno: formdata.mobileno});
				this.setState({dob: formdata.dob});
				this.setState({dob_change: new Date()});
				this.setState({parent_name: formdata.parent_name});
				this.setState({parent_mobileno: formdata.parent_mobileno});
				this.setState({address: formdata.address});
				this.setState({from_lat: formdata.u_loc_lat});
				this.setState({from_long: formdata.u_loc_long});
				this.setState({student_story: formdata.about_student_story});
				this.setState({no_of_kids: formdata.no_kids});
				this.setState({learning_hours: formdata.learning_hours});
				this.setState({amount: formdata.amount});
				this.setState({donation_limit_amount: formdata.donation_limit_amount});
				this.setState({studentId: formdata.id});
				this.setState({share_content: formdata.share_content});
				this.setState({selectedvideoOption: formdata.share_content_status});
				this.setState({tutor_account: formdata.tutor_account});
				
				if(formdata.donation_start_date !== null){
					var start_date = new Date(formdata.donation_start_date);
					this.setState({donation_start_date: start_date});
				}else {
					this.setState({donation_start_date: new Date()});
				}

				if(formdata.donation_end_date !== null){
					var end_date = new Date(formdata.donation_end_date);
					this.setState({donation_end_date: end_date});
				}else {
					this.setState({donation_end_date: new Date()});
				}
				
				
				if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
					if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
						this.setState({ selecteddomain : res.data.selecteddomain[0]})
						}
				}



				if(res.data.selectedfund!==null && res.data.selectedfund.length > 0){
					if(res.data.selectedfund[0].value!== '' && res.data.selectedfund.value!== null){
						this.setState({ selectedFund : res.data.selectedfund[0]})
						}
				}
				// if(res.data.studentlist.length > 0){
					if(res.data.fundlist!== '' && res.data.fundlist!== null){
						this.setState({ fundlist : res.data.fundlist})
					}
				// }

				if(res.data.selecteduniversity!==null && res.data.selecteduniversity.length > 0){
					if(res.data.selecteduniversity[0].value!== '' && res.data.selecteduniversity.value!== null){
						this.setState({ selectedUniversity : res.data.selecteduniversity[0]})
						}
				}
				// if(res.data.studentlist.length > 0){
					if(res.data.universitylist!== '' && res.data.universitylist!== null){
						this.setState({ universitylist : res.data.universitylist})
					}
				// }


				if(res.data.selectedkollel!==null && res.data.selectedkollel.length > 0){
					if(res.data.selectedkollel[0].value!== '' && res.data.selectedkollel.value!== null){
						this.setState({ selectedKollel : res.data.selectedkollel[0]})
						}
				}
				// if(res.data.studentdetails.length > 0){
					if(res.data.kollellist!== '' && res.data.kollellist!== null){
						this.setState({ kollellist : res.data.kollellist})
					}
				// }
			}	
		});


		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }

	handleChangeFund = selectedFund => {
		this.setState({ selectedFund});
		this.setState({ fundvalue : selectedFund.value });
		/*if(selectedFund.value === ''){
		  $('.errorassign_fund').html('<span class="errorspan">Please select the field</span>');
		}*/
	 }
	 handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });

		
	 }

	  handleProfileOptionChange= (changeEvent) => {
		this.setState({
		  selectedvideoOption: changeEvent.target.value
		});
	  }

	 handleChangeUniversity = selectedUniversity => {

		if(this.state.selectedkollel){
			$('.erroruniversity_name').html('<span class="errorspan">You already assigned to kollel</span>');

				setTimeout(
				function() {
					$('.erroruniversity_name').html('');
				}
				.bind(this),
				3000
				);
		}else{
			this.setState({ selectedUniversity});
		    this.setState({ universityvalue : selectedUniversity.value });
		}
	 }

	 handleChangeKollel = selectedKollel => {
	 	if(this.state.selectedUniversity){
			$('.errorkollel').html('<span class="errorspan">You already assigned to yeshiva</span>');
			setTimeout(
				function() {
					$('.errorkollel').html('');
				}
				.bind(this),
				3000
				);
		}else{
			this.setState({ selectedKollel});
		    this.setState({ kollelvalue : selectedKollel.value });
		}	
	 }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		const google = window.google;
		const callfunc = this;

		if(name == 'address'){
			var input = document.getElementById('place_event');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
		//	document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress1 = place.name;
			var locationAddress = place.formatted_address;
			var fullplace = place.name+','+locationAddress;
			callfunc.setState({address: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			callfunc.setState({fulldroploc : fullplace});
			});
	}
    }

	getgoecoder(input, name){

		$('#place_event').val('');
			
		if(name == 'address'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng,
					 from_lat: lat,
					 address: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}

	componentDidMount() {
		document.title = PageTitle('Student Edit');

		var admin_id = localStorage.getItem('admin_id');
		var loggedas = localStorage.getItem('loggedas');
		axios.get(apiUrl+'student/getdomainlist?admin_id='+admin_id+'&loggedas='+loggedas)			
		.then(res => {
			if(res.data.status == 'success'){
				console.log(res.data);				
				this.setState({
					domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
				})				
			}else{
			//console.log("test")
			}
		});
    }

	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}
		
		confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}


        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
					status =formPayload.selectedOption.value;
					}else{
					status = formPayload.selectedvalue;
					}
	
					var assignfund;
					if(formPayload.selectedFund !== ''){
					assignfund =formPayload.selectedFund.value;
					}else{
					assignfund =formPayload.fundvalue;
					}
	
					var assignuniversity;
					if(formPayload.selectedUniversity !== ''){
					assignuniversity =formPayload.selectedUniversity.value;
					}else{
					assignuniversity =formPayload.universityvalue;
					}


					var kollel_value;

					if(formPayload.selectedKollel !== ''){
						console.log(formPayload.selectedKollel)
					  kollel_value =formPayload.selectedKollel.value;
					}else{
					  kollel_value =formPayload.kollelvalue;
					}


					if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
					status =formPayload.selectedOption.value;
					}else{
					status = formPayload.selectedvalue;
					}
				    var postObject = {
					admin_id : localStorage.getItem("admin_id"),
                    studentId       : formPayload.studentId,
                   // domain_id : formPayload.domain_id,
					name: formPayload.name,
					username:formPayload.username,
                    age: formPayload.age,
					changepassword:formPayload.changepassword,					
					university_name:formPayload.university_name,
					kollel:kollel_value,
					mobileno:formPayload.mobileno,
					dob:formPayload.dob,
					dob_change:formPayload.dob_change,
					donation_start_date:formPayload.donation_start_date,
					donation_end_date:formPayload.donation_end_date,
					email:formPayload.email,
					parent_name: formPayload.parent_name,
					parent_mobileno: formPayload.parent_mobileno,
					student_story:formPayload.student_story,
					no_of_kids: formPayload.no_of_kids,
					learning_hours: formPayload.learning_hours,
					amount:formPayload.amount,
					donation_limit_amount: formPayload.donation_limit_amount,
					assign_fund: assignfund,
					assign_university: assignuniversity,
					status       :  status,
					 share_content_status : formPayload.selectedvideoOption,	
					 loggedas:localStorage.getItem('loggedas'),
					 domain:formPayload.selecteddomain_value,
					 tutor_account:formPayload.tutor_account
				};
				axios.post(apiUrl+"student/edit",qs.stringify(postObject)).then(res => {
					if(res.data.status === 'success'){

						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');						
						
					const formdata = res.data.studentlist;

					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

					}

					this.setState({name:formdata.name});
					this.setState({username:formdata.username});						
					this.setState({age: formdata.age});						
					this.setState({university_name: formdata.university_name});
					this.setState({email: formdata.email});
					this.setState({school_name: formdata.school_name});
					this.setState({mobileno: formdata.mobileno});
					this.setState({dob: formdata.dob});
					this.setState({dob_change: new Date()});
					this.setState({parent_name: formdata.parent_name});
					this.setState({parent_mobileno: formdata.parent_mobileno});
					this.setState({address: formdata.address});
					this.setState({from_lat: formdata.u_loc_lat});
					this.setState({from_long: formdata.u_loc_long});
					this.setState({student_story: formdata.about_student_story});
					this.setState({no_of_kids: formdata.no_kids});
					this.setState({learning_hours: formdata.learning_hours});
					this.setState({amount: formdata.amount});
					this.setState({donation_limit_amount: formdata.donation_limit_amount});
					this.setState({studentId: formdata.id});
					this.setState({share_content: formdata.share_content});
					this.setState({selectedvideoOption: formdata.share_content_status});
					this.setState({tutor_account: formdata.tutor_account});
				 
					if(formdata.donation_start_date !== null){
						var start_date = new Date(formdata.donation_start_date);
						this.setState({donation_start_date: start_date});
					}else {
						this.setState({donation_start_date: new Date()});
					}

					if(formdata.donation_end_date !== null){
						var end_date = new Date(formdata.donation_end_date);
						this.setState({donation_end_date: end_date});
					}else {
						this.setState({donation_end_date: new Date()});
					}
					
					
					if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
						if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
							this.setState({ selecteddomain : res.data.selecteddomain[0]})
							}
					}



					if(res.data.selectedfund!==null && res.data.selectedfund.length > 0){
						if(res.data.selectedfund[0].value!== '' && res.data.selectedfund.value!== null){
							this.setState({ selectedFund : res.data.selectedfund[0]})
							}
					}
					// if(res.data.studentlist.length > 0){
						if(res.data.fundlist!== '' && res.data.fundlist!== null){
							this.setState({ fundlist : res.data.fundlist})
						}
					// }

					if(res.data.selecteduniversity!==null && res.data.selecteduniversity.length > 0){
						if(res.data.selecteduniversity[0].value!== '' && res.data.selecteduniversity.value!== null){
							this.setState({ selectedUniversity : res.data.selecteduniversity[0]})
							}
					}
					// if(res.data.studentlist.length > 0){
						if(res.data.universitylist!== '' && res.data.universitylist!== null){
							this.setState({ universitylist : res.data.universitylist})
						}
					// }


					if(res.data.selectedkollel!==null && res.data.selectedkollel.length > 0){
						if(res.data.selectedkollel[0].value!== '' && res.data.selectedkollel.value!== null){
							this.setState({ selectedKollel : res.data.selectedkollel[0]})
							}
					}
					// if(res.data.studentdetails.length > 0){
						if(res.data.kollellist!== '' && res.data.kollellist!== null){
							this.setState({ kollellist : res.data.kollellist})
						}
					// }

						setTimeout(
						function(){							
							$('.success_message').html('');
							// const navigate = this.props.navigate;
							this.props.navigate('/student');
							}
						.bind(this),
						3000
						);
					}else{
					this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
				// this.props.getEditStudent(qs.stringify(postObject));
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {name,username,age,changepassword,dob,donation_start_date,donation_end_date,user_confirm_password,university_name,school_name,email,parent_name,parent_mobileno,mobileno,address,student_story,amount,donation_limit_amount,selectedUniversity,selectedFund,selectedKollel,selecteddomain} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!name) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}	

		if (!username) {
			formIsValid = false;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}

		if (!age) {
			formIsValid = false;
			$('.errorage').html('<span class="errorspan">Please fill the field</span>');
		}else if(age){
			$('.errorage').html('');
		}				

        if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		if (!dob) {
			formIsValid = false;
			$('.errordob').html('<span class="errorspan">Please fill the field</span>');
		}else if(dob){
			$('.errordob').html('');
		}

		if (!donation_start_date) {
			formIsValid = false;
			$('.errordonation_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_start_date){
			$('.errordonation_start_date').html('');
		}

		if (!donation_end_date) {
			formIsValid = false;
			$('.errordonation_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_end_date){
			$('.errordonation_end_date').html('');
		}

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

        /*if (!school_name) {
			formIsValid = false;
			$('.errorschool_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(school_name){
			$('.errorschool_name').html('');
		}*/


	/*	if (selectedFund === '') {
			$('.errorassign_fund').html('<span class="errorspan">Please select the field</span>');
			formIsValid = false;
			}
			else {
			$('.errorassign_fund').html('');
			}*/

		if(!selectedUniversity && !selectedKollel){
			formIsValid = false;
        	$('.errorkollel').html('<span class="errorspan">Please choose any one of the type Yeshiva/Kollel</span>');
        	$('.erroruniversity_name').html('<span class="errorspan">Please choose any one of the type Yeshiva/Kollel</span>');
		}else{
			$('.errorkollel').html('');
			$('.erroruniversity_name').html('');
		}


		/*if(changepassword !=''){
			if (changepassword.match(/[a-z]/g) && changepassword.match( 
					   /[A-Z]/g) && changepassword.match( 
					   /[0-9]/g) && changepassword.match( 
					   /[^a-zA-Z\d]/g) && changepassword.length >= 8){
							$('.errorpassword').html('');
			}else{
			   formIsValid = false;
			   $('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
			}
		   }*/
		   
		   if(user_confirm_password !=''){
			if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
					   /[A-Z]/g) && user_confirm_password.match( 
					   /[0-9]/g) && user_confirm_password.match( 
					   /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
							$('.errorchangepaswd').html('');
			}else{
				formIsValid = false;
			   $('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
			}
			
		   if(changepassword !== user_confirm_password){
				formIsValid = false;
			   $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
		   }
		}

	/*	if (!parent_name) {
			formIsValid = false;
			$('.errorparent_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(parent_name){
			$('.errorparent_name').html('');
		}

		if (!parent_mobileno) {
			formIsValid = false;
			$('.errorparent_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(parent_mobileno){
			$('.errorparent_mobileno').html('');
		}

		if (!address) {
			formIsValid = false;
			$('.erroraddress').html('<span class="errorspan">Please fill the field</span>');
		}else if(parent_mobileno){
			$('.erroraddress').html('');
		}

		if (!student_story) {
			formIsValid = false;
			$('.errorstudent_story').html('<span class="errorspan">Please fill the field</span>');
		}else if(student_story){
			$('.errorstudent_story').html('');
		}*/

		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			$('.erroramount').html('');
		}

		if (!donation_limit_amount) {
			formIsValid = false;
			$('.errordonation_limit_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_limit_amount){
			$('.errordonation_limit_amount').html('');
		}
		if (!selecteddomain) {
			formIsValid = false;
			$('.errordomain').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain){
			$('.errordomain').html('');
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    // componentWillReceiveProps(Props){

   	// if(Props.studentdetails !== this.props.studentdetails){
    // 		if(Object.keys(Props.studentdetails).length > 0){
	// 			this.setState({Loading:false});

	// 				if(Props.studentdetails[0].status === "success"){

	// 					const formdata = Props.studentdetails[0].studentlist;

	// 					if(formdata.status === 'A'){
	// 						this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 					}else{
	// 						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

	// 					}

	// 					this.setState({name:formdata.name});
	// 					this.setState({username:formdata.username});						
	// 					this.setState({age: formdata.age});						
	// 					this.setState({university_name: formdata.university_name});
	// 					this.setState({email: formdata.email});
    //                     this.setState({school_name: formdata.school_name});
    //                     this.setState({mobileno: formdata.mobileno});
	// 					this.setState({dob: formdata.dob});
	// 					this.setState({dob_change: new Date()});
	// 					this.setState({parent_name: formdata.parent_name});
    //                     this.setState({parent_mobileno: formdata.parent_mobileno});
	// 					this.setState({address: formdata.address});
	// 					this.setState({from_lat: formdata.u_loc_lat});
	// 					this.setState({from_long: formdata.u_loc_long});
    //                     this.setState({student_story: formdata.about_student_story});
    //                     this.setState({no_of_kids: formdata.no_kids});
    //                     this.setState({learning_hours: formdata.learning_hours});
	// 					this.setState({amount: formdata.amount});
	// 					this.setState({donation_limit_amount: formdata.donation_limit_amount});
    //                     this.setState({studentId: formdata.id});
    //                     this.setState({share_content: formdata.share_content});
    //                     this.setState({selectedvideoOption: formdata.share_content_status});
    //                     this.setState({tutor_account: formdata.tutor_account});
                     
    //                     if(formdata.donation_start_date !== null){
    //                     	var start_date = new Date(formdata.donation_start_date);
	// 					    this.setState({donation_start_date: start_date});
    //                     }else {
    //                     	this.setState({donation_start_date: new Date()});
    //                     }

    //                     if(formdata.donation_end_date !== null){
	// 						var end_date = new Date(formdata.donation_end_date);
	// 					    this.setState({donation_end_date: end_date});
    //                     }else {
    //                     	this.setState({donation_end_date: new Date()});
    //                     }
                        
						
	// 					if(Props.studentdetails[0].selecteddomain!==null && Props.studentdetails[0].selecteddomain.length > 0){
	// 						if(Props.studentdetails[0].selecteddomain[0].value!== '' && Props.studentdetails[0].selecteddomain.value!== null){
	// 							this.setState({ selecteddomain : Props.studentdetails[0].selecteddomain[0]})
	// 							}
	// 					}



	// 					if(Props.studentdetails[0].selectedfund!==null && Props.studentdetails[0].selectedfund.length > 0){
	// 						if(Props.studentdetails[0].selectedfund[0].value!== '' && Props.studentdetails[0].selectedfund.value!== null){
	// 							this.setState({ selectedFund : Props.studentdetails[0].selectedfund[0]})
	// 							}
	// 					}
	// 					if(Props.studentdetails.length > 0){
	// 						if(Props.studentdetails[0].fundlist!== '' && Props.studentdetails[0].fundlist!== null){
	// 							this.setState({ fundlist : Props.studentdetails[0].fundlist})
	// 						}
	// 					}

	// 					if(Props.studentdetails[0].selecteduniversity!==null && Props.studentdetails[0].selecteduniversity.length > 0){
	// 						if(Props.studentdetails[0].selecteduniversity[0].value!== '' && Props.studentdetails[0].selecteduniversity.value!== null){
	// 							this.setState({ selectedUniversity : Props.studentdetails[0].selecteduniversity[0]})
	// 							}
	// 					}
	// 					if(Props.studentdetails.length > 0){
	// 						if(Props.studentdetails[0].universitylist!== '' && Props.studentdetails[0].universitylist!== null){
	// 							this.setState({ universitylist : Props.studentdetails[0].universitylist})
	// 						}
	// 					}


	// 					if(Props.studentdetails[0].selectedkollel!==null && Props.studentdetails[0].selectedkollel.length > 0){
	// 						if(Props.studentdetails[0].selectedkollel[0].value!== '' && Props.studentdetails[0].selectedkollel.value!== null){
	// 							this.setState({ selectedKollel : Props.studentdetails[0].selectedkollel[0]})
	// 							}
	// 					}
	// 					if(Props.studentdetails.length > 0){
	// 						if(Props.studentdetails[0].kollellist!== '' && Props.studentdetails[0].kollellist!== null){
	// 							this.setState({ kollellist : Props.studentdetails[0].kollellist})
	// 						}
	// 					}

                        
	// 				}else{
	// 					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.studentdetails[0].message+'</h3></div>');
	// 					setTimeout(
	// 						function() {
	// 							$('.success_message').html();
	// 						}
	// 						.bind(this),
	// 						5000
	// 					);
	// 				}
    // 		}
    // 	}

    // 	if(Props.studentedit !== this.props.studentedit){
    // 		if(Object.keys(Props.studentedit).length > 0){
	// 			if(Props.studentedit[0].status === "success"){
    // 					this.setState({ Loading: false });

    // 			const formpayload = Props.studentedit[0].studentlist;

	// 			if(formpayload.status === 'A'){
	// 				this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 				}else{
	// 				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
	
	// 				}

	// 			this.setState({name:formpayload.name});	
	// 			this.setState({username:formpayload.username});					
	// 			this.setState({age: formpayload.age});						
	// 			this.setState({university_name: formpayload.university_name});
	// 			this.setState({dob: formpayload.dob});
	// 			this.setState({email: formpayload.email});
    //             this.setState({school_name: formpayload.school_name});
    //             this.setState({mobileno: formpayload.mobileno});
	// 			this.setState({parent_name: formpayload.parent_name});
    //             this.setState({parent_mobileno: formpayload.parent_mobileno});
	// 			this.setState({address: formpayload.address});
	// 			this.setState({from_lat: formpayload.u_loc_lat});
	// 		    this.setState({from_long: formpayload.u_loc_long});	
    //             this.setState({student_story: formpayload.about_student_story});
	// 			this.setState({amount: formpayload.amount});
	// 			this.setState({donation_limit_amount: formpayload.donation_limit_amount});
    //             this.setState({studentId: formpayload.id});
    //              this.setState({share_content: formpayload.share_content});
    //              this.setState({selectedvideoOption: formpayload.share_content_status});

	// 			if(formpayload.donation_start_date !== null){
	// 			var start_date = new Date(formpayload.donation_start_date);
	// 			this.setState({donation_start_date: start_date});
	// 			}else {
	// 			this.setState({donation_start_date: new Date()});
	// 			}

	// 			if(formpayload.donation_end_date !== null){
	// 			var end_date = new Date(formpayload.donation_end_date);
	// 			this.setState({donation_end_date: end_date});
	// 			}else {
	// 			this.setState({donation_end_date: new Date()});
	// 			}
				
    //            	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.studentedit[0].message+'</h3></div>');
    //            			scrollToTop();
    // 					setTimeout(
	// 					function() {
	// 						$('.success_message').html('');
	// 						this.props.navigate('/student');
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
						
    // 		}else{
	// 				this.setState({ Loading: false });
	// 				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.studentedit[0].message+'</h3></div>');
	// 				scrollToTop();
    // 					setTimeout(
	// 					function() {
	// 						$('.success_message').html('');
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
	// 		}
	// 		}
    // 	}
		
    // }

	setselectedfund(selectsers){
		const fundArray = selectsers.map ((fundlist, index) => ({
		 id: index,
		 name: fundlist,
		}));
		// Dynamically create select list
		let funds = [];
		fundArray.map(item =>
		funds.push({ label: item.name.label, value: item.name.value }),
		);
		this.setState({selectedFund : funds})
	  }

	  setselecteduniversity(selectsers){
		const universityArray = selectsers.map ((universitylist, index) => ({
		 id: index,
		 name: universitylist,
		}));
		// Dynamically create select list
		let universitys = [];
		universityArray.map(item =>
		universitys.push({ label: item.name.label, value: item.name.value }),
		);
		this.setState({selectedUniversity : universitys})
	  }

	  onChangedatetime1 = (date) => {
		this.setState({ dob_change: date })
		this.setState({ dob: date })
	}

	onChangedatetime2 = (date) => { 
	   this.setState({ donation_start_date: date })
	}
	
	onChangedatetime3 = (date) => { 
		  this.setState({ donation_end_date: date })
	}
	
  render() {

	const fundArray = this.state.fundlist.map ((fundlist, index) => ({
		id: index,
		name: fundlist,
		}));

		// Dynamically create select list
		let funds = [];
		fundArray.map(item =>
		funds.push({ label: item.name.label, value: item.name.value }),
		);

		const domainArray = this.state.domainlist.map ((domainlist, index) => ({
			id: index,
			name: domainlist,
			}));
			
			// Dynamically create select list
			let domains = [];
			domainArray.map(item =>
				domains.push({ label: item.name.label, value: item.name.value }),
			);


		const universityArray = this.state.universitylist.map ((universitylist, index) => ({
			id: index,
			name: universitylist,
			}));
			// Dynamically create select list
			let universitys = [];
			universityArray.map(item =>
			universitys.push({ label: item.name.label, value: item.name.value }),
			);


	        const kollelArray = this.state.kollellist.map ((kollellist, index) => ({
			id: index,
			name: kollellist,
			}));
			// Dynamically create select list
			let kollel = [];
			kollelArray.map(item =>
			kollel.push({ label: item.name.label, value: item.name.value }),
			);



  	  const {selectedOption,selectedFund,selectedUniversity,selectedKollel,selecteddomain,domainlist} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="studentedit" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Avrech Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				{localStorage.getItem('loggedas') != 'kollel' &&
				<div className="form-group">					
					<label>Kollel : </label>
						<Select 
                         options={kollel}  
                         value={selectedKollel?selectedKollel:{ value: '0', label: 'Select Kollel' }}
                         onChange={this.handleChangeKollel}
                         placeholder="Select Kollel" />
					<div className="errorkollel"></div>
				</div>	}

				<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>

				{/*<div className="form-group">
					<label>Age<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="age" onChange={this.handleInputChange} className="form-control" value={this.state.age} />
					<div className="errorage"></div>
				</div>	*/}							

				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>

				{/*<div className="form-group">
					<label>Kollel<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="school_name" onChange={this.handleInputChange} className="form-control" value={this.state.school_name} />
					<div className="errorschool_name"></div>
				</div>*/}

				<div className="form-group">					
					<label>Goal ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>
				<div className="form-group">
					<label>Parent Name:</label>
						<input type="text" name="parent_name" onChange={this.handleInputChange} className="form-control" value={this.state.parent_name} />
					<div className="errorparent_name"></div>
				</div>	

				<div className="form-group">					
					<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_start_date}
				        onChange={this.onChangedatetime2}
				        dateFormat="MM/dd/yyy"
				        showMonthDropdown
                        showYearDropdown
						value={this.state.donation_start_date}
				        />
					<div className="errordonation_start_date"></div>
					
				</div>

				<div className="form-group eye-pad">
                    <label>Reset Password</label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>	

				
				  <div className="form-group">
					<label>Number Of Kids:</label>
						<input type="text" name="no_of_kids" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_kids} />
					<div className="errorno_of_kids"></div>
				</div>

				<div className="form-group">					
					<label>Fund Category : </label>
						<Select 
                         options={funds}  
                         value={selectedFund?selectedFund:{ value: '0', label: 'Select Fund' }}
                         onChange={this.handleChangeFund}
                         placeholder="Select Fund" />
				</div>	

				<div className="form-group">					
					<label>Interested as Melamed : </label>
					<input type="text" className='form-control' value={this.state.tutor_account} readOnly />
						{/* <input type="radio" name="tutor_account" value="Yes" onChange={this.handleInputChange} checked={this.state.tutor_account == 'Yes'} /> Yes
						<input type="radio" name="tutor_account" value="No" onChange={this.handleInputChange} checked={this.state.tutor_account == 'No'} /> No */}
					<div className="errorstudent_story"></div>
				</div>

				{/* {localStorage.getItem('loggedas') != 'kollel' &&
				<div className="form-group">					
					<label>Yeshiva: </label>
						<Select 
                         options={universitys}  
                         value={selectedUniversity?selectedUniversity:{ value: '0', label: 'Select University' }}
                         onChange={this.handleChangeUniversity}
                         placeholder="Select University" />
					<div className="erroruniversity_name"></div>
				</div>} */}
				   

							
			</div>	
			<div className="form-right">

			<div className="form-group">
					<label>Username<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} readOnly />
					<div className="errorusername"></div>
				</div>

				<div className="form-group">					
					<label>Date Of Birth<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
						 selected={this.state.dob_change}
						 onChange={this.onChangedatetime1}
						 dateFormat="yyy-MM-dd"
						 showMonthDropdown
                        showYearDropdown
						 maxDate={new Date()}
						 value={moment(this.state.dob).format('L')}
					  /> 
					<div className="errordob"></div>
				  
				</div>
				
				<div className="form-group">					
					<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
					<div className="errormobileno"></div>
				</div>

				{/*<div className="form-group">					
					<label>Kollel Location : </label>
						<input type="text" id="place_event" placeholder="Enter a location" name="address" onChange={this.handleInputChange} className="form-control" value={this.state.address} />
					<div className="erroraddress"></div>
				</div>
                 */}
				<div className="form-group">
					<label>Goal Limit Amount($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
					<div className="errordonation_limit_amount"></div>
				</div>

				<div className="form-group">
					<label>Parent Mobile Number:</label>
						<input type="text" name="parent_mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.parent_mobileno} />
					<div className="errorparent_mobileno"></div>
				</div>
				{localStorage.getItem('admin_id') !== '1' ?
				<><div className="form-group eye-pad">
                    <label>Confirm Password</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                   <div className="errorchangepaswd"></div></>: ''}

				   <div className="form-group">					
					<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_end_date}
				        onChange={this.onChangedatetime3}
				        dateFormat="MM/dd/yyy"
				        showMonthDropdown
                        showYearDropdown
						value={this.state.donation_end_date}
				        />
					<div className="errordonation_end_date"></div>
					
				</div>	
				<div className="form-group">					
					<label>Domain List : </label>
						<Select 
                         options={domains}  
                         value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
                         onChange={this.handleChangeDomain}
                         placeholder="Select Domain" />
						 <div className="errordomain"></div>
				</div>	

				<div className="form-group">
					<label>Learning Hours:</label>
						<input type="text" name="learning_hours" onChange={this.handleInputChange} className="form-control" value={this.state.learning_hours} />
					<div className="errorlearning_hours"></div>
				</div>
				
				<div className="form-group">					
					<label>About Student Story : </label>
						<textarea type="text" name="student_story" onChange={this.handleInputChange} className="form-control" value={this.state.student_story} />
					<div className="errorstudent_story"></div>
				</div>

				

				
				 {/* <div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>	*/}

				

				

			</div>
			</div>	
			{((this.state.share_content != '') && (this.state.share_content != null))?<>
					<div className="title">
					<h3>Video given by avrech</h3>
				    </div>
				    <div className="form-group">
					<label>Video Approve:</label>
					<div className="custom_radio">	
						<input type="radio"  value="yes" checked={this.state.selectedvideoOption === 'yes'} onChange={this.handleProfileOptionChange} />
						<span>Yes</span>
				   </div>
				   <div className="custom_radio">
						<input type="radio" value="no" checked={this.state.selectedvideoOption === 'no'} onChange={this.handleProfileOptionChange} />
						<span>No</span>
					</div>
					<div className="errorstudentms_p"></div>
				</div>
				<div className="form-group">
		    	<label>Avrech Shared Content:</label>
						<div className="video-file">
								<iframe width="450" height="215" src={this.state.share_content} title="Yeshiva video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
                 </div>

                 </> : '' }	


				

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}

export default withRouter(Edit);