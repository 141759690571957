import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
};
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
            domain_id:'',
			kollel_name:'',
			username:'',			
			kollel_location:'',
			website:'',
			description:'',
			email:'',
			mobileno:'',
			established_year:'',
			established_year_change: new Date(),
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			domainlist:[],
			statusmessage:'',
			Loading:false,
			donation:'',
			donation_limit_amount:'',
			donation_start_date: '',
			donation_end_date: '',
			user_password: '',
            user_confirm_password: '',
			new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
			domainlist:[],
			selecteddomain:'',
			selecteddomain_value:''
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
   		
		const {name, value} = event.target;   
		this.setState({
			[name]: value
		});

		const google = window.google;
		const callfunc = this;

		if(name == 'kollel_location'){
			var input = document.getElementById('place_event');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
			document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress1 = place.name;
			var locationAddress = place.formatted_address;
			var fullplace = place.name+','+locationAddress;
			callfunc.setState({kollel_location: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			callfunc.setState({fulldroploc : fullplace});
			});
	     }
   }

	getgoecoder(input, name){

		$('#place_event').val('');
			
		if(name == 'kollel_location'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					kollel_location: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
			$('.errorkollel_location').html('');
		}

	}

	componentDidMount() {
		document.title = PageTitle('Kollel Add');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

		var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'user/getdomainlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
					})
				
				}else{
				//console.log("test")
				}
			});
    }

		new_pass_show_hide = () => {
			this.setState({
			new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}

		confim_pass_show_hide = () => {
			this.setState({
			confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
		} 

     handleFormSubmit = () => {
        	
		if(this.validateForm()){
			this.setState({Loading:true});
			const formPayload = this.state;
			
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				username : formPayload.username,
				password : formPayload.user_password,
				kollel_name: formPayload.kollel_name,
                kollel_location: formPayload.kollel_location,
				kollel_loc_lat:formPayload.from_lat,
				kollel_loc_long:formPayload.from_long,					
				website:formPayload.website,
				description: formPayload.description,
				mobileno:formPayload.mobileno,
				email:formPayload.email,
				established_year: formPayload.established_year,
				established_year_change:formPayload.established_year_change,
				goal:  formPayload.donation,
				goal_limit_amount:  formPayload.donation_limit_amount,
				goal_start_date:formPayload.donation_start_date,
				goal_end_date:formPayload.donation_end_date,
				domain:formPayload.selecteddomain_value
			};

			axios.post(apiUrl+"kollel/add",qs.stringify(postObject)).then(res => {
				if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							Loading:false
						});
						 setTimeout(
						 function(){							
							 $('.success_message').html('');
							 // const navigate = this.props.navigate;
							 this.props.navigate('/kollel');
							}
						.bind(this),
						3000
						);
					}else{
					 this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
			});
		}
	}

	validateForm() {
		const {username ,kollel_name,kollel_location,email,established_year,mobileno,donation,donation_limit_amount,donation_start_date,donation_end_date,user_password,user_confirm_password,selecteddomain} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!username) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorname').html('');
		}

		if (!kollel_name) {
			formIsValid = false;
			$('.errorkollel_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(kollel_name){
			$('.errorkollel_name').html('');
		}
		
		if (!kollel_location) {
			formIsValid = false;
			$('.errorkollel_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(kollel_location){
			$('.errorkollel_location').html('');
		}	
        

		if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

		if (!established_year) {
			formIsValid = false;
			$('.errorestablished_year').html('<span class="errorspan">Please fill the field</span>');
		}else if(established_year){
			$('.errorestablished_year').html('');
		}

		if (!donation_start_date) {
			formIsValid = false;
			$('.errordonation_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_start_date){
			$('.errordonation_start_date').html('');
		}

		if (!donation_end_date) {
			formIsValid = false;
			$('.errordonation_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_end_date){
			$('.errordonation_end_date').html('');
		}

		 if(user_password){
		 if (user_password.match(/[a-z]/g) && user_password.match( 
                    /[A-Z]/g) && user_password.match( 
                    /[0-9]/g) && user_password.match( 
                    /[^a-zA-Z\d]/g) && user_password.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	formIsValid = false;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		if(user_confirm_password){
		 if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
                    /[A-Z]/g) && user_confirm_password.match( 
                    /[0-9]/g) && user_confirm_password.match( 
                    /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	formIsValid = false;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		
		if(user_password !== user_confirm_password){
          	formIsValid = false;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }
		if (!selecteddomain) {
			formIsValid = false;
			$('.errordomain').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain){
			$('.errordomain').html('');
		}

		return formIsValid;
    }

	handleClickSelecetdomain = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				selected_domain: key,
				
		})


	}

    componentWillReceiveProps(Props){

   	if(Props.kolleladd !== this.props.kolleladd){
    		if(Object.keys(Props.kolleladd).length > 0){
				this.setState({Loading:false});

					if(Props.kolleladd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.kolleladd[0].message+'</h3></div>');
						this.setState({
							kollel_name:'',			
			                kollel_location:'',
			                website:'',
			                description:'',
			                email:'',
			                mobileno:'',
			                established_year:'',
							domain_id:'',
							selectedvalue: ''
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/kollel'));
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.kolleladd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	}

   onChangedatetime1 = (date) => { 

       this.setState({ established_year_change: date })
	   this.setState({ established_year: date })

   }
	

	onChangegoalenddate = (date) => { 

	 this.setState({ donation_end_date: date })

	}

	onChangegoalstartdate = (date) => { 

	 this.setState({ donation_start_date: date })

    }

  render() {
	let preview = '';
	const {selectedOption,selecteddomain,domainlist} = this.state;
	var current = this;
	let domain_list = [{ label: 'shiviti.co', value: 82 }];
	const domainArray = domain_list.map ((domainlist, index) => ({
		id: index,
		name: domainlist,
		}));
		// Dynamically create select list
		let domains = [];
		domainArray.map(item =>
			domains.push({ label: item.name.label, value: item.name.value }),
		);
	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="kollel" />  

		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
			<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
				<div className="form-wrapper">
				<div className="success_message"></div>
				<div className="title">
					<h4>Add Kollel</h4>				
				</div>
				 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
				<div className="form-row">
				<div className="form-left">
					<div className="form-group">
						<label>Name<span class="required" style={{ color: "red" }} > * </span> : </label>
					    <input type="text" name="kollel_name" onChange={this.handleInputChange} className="form-control" value={this.state.kollel_name} />
						<div className="errorkollel_name"></div>
					</div>

					 <div className="form-group">
						<label>Email<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
						<div className="erroremail"></div>
					</div>			
				
					

					<div className="form-group">
						   <label>Established Year<span class="required" style={{ color: "red" }} > * </span> : </label>
					  
						    <DatePicker
						    showYearDropdown	
					        selected={this.state.established_year_change}
					        onChange={this.onChangedatetime1}
					        dateFormat="MM/dd/yyy"
					        showMonthDropdown
							maxDate={new Date()}
							value={this.state.established_year}
							yearDropdownItemNumber={100}
							scrollableYearDropdown
					        />
					   <div className="errorestablished_year"></div>
					  
					</div>
					<div className="form-group">
						<label>Goal ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="donation" onChange={this.handleInputChange} className="form-control" value={this.state.donation} placeholder="Enter Goal ($)"/>
						<div className="errordonation"></div>
					</div>
	 				<div className="form-group">					
						<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
						
						    <DatePicker
					        selected={this.state.donation_start_date}
					        onChange={this.onChangegoalstartdate}
					        dateFormat="MM/dd/yyy"
					        showMonthDropdown
                            showYearDropdown
							value={this.state.donation_start_date}
					        />
						<div className="errordonation_start_date"></div>
						
					</div>
					<div className="form-group eye-pad">
	                    <label>New Password</label>
	                    <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleInputChange.bind(this)} autoComplete="off"/>
	                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
	                 </div>
	                  <div className="errorpassword"></div>
	                  <div className="form-group">
						<label>Location<span class="required" style={{ color: "red" }} > * </span> : </label>
					    <input type="text" id="place_event" placeholder="Enter a  location" name="kollel_location" onChange={this.handleInputChange} className="form-control" value={this.state.kollel_location} />
						<div className="errorkollel_location"></div>
					</div>
					<div className="form-group">
					<label>Domain List: </label>
				    <Select 
					options={domains}  
					value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
					onChange={this.handleChangeDomain}
					placeholder="Select Domain" />
					<div className="errordomain"></div>
				 </div>


				</div>
				<div className="form-right">

					<div className="form-group">
						<label>Username<span class="required" style={{ color: "red" }} > * </span> : </label>
					    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off" />
						<div className="errorusername"></div>
					</div>
					<div className="form-group">					
						<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
						<div className="errormobileno"></div>
					</div>
					<div className="form-group">
						<label>Website : </label>
					    <input type="text" name="website" onChange={this.handleInputChange} className="form-control" value={this.state.website} />
					</div>
					<div className="form-group">
						<label>Goal Limit($)<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
						<div className="errordonation_limit_amount"></div>
					</div>
					<div className="form-group">					
						<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
						
						    <DatePicker
					        selected={this.state.donation_end_date}
					        onChange={this.onChangegoalenddate}
					        dateFormat="MM/dd/yyy"
					        showMonthDropdown
                            showYearDropdown
							value={this.state.donation_end_date}
					        />
						<div className="errordonation_end_date"></div>
						
					</div>
					 <div className="form-group eye-pad">
	                    <label>Confirm Password</label>
	                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
	                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
	                  </div>
	                   <div className="errorchangepaswd"></div>	
					<div className="form-group">					
						<label>Description:</label>
							<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description} />
					</div>
				   </div>	
				</div>		

				<div className="btn-group export">	
					<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
										<span className="load-data"></span> 
										}Submit
				   </button>
				</div>
				</form>
				</div>
					
			</div>	
		</div>
			 	</div>
    </div>
    );
  }
}

export default withRouter(Add);