import React, { Component } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

import { apiUrl} from'../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
	  />
	);
  };

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);
		const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		// const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			userid: userid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
			user_confirm_password: '',
			 new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			domainlist:[],
			selecteddomain:'',
			selecteddomain_value:''	
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	  
	   var qs = require('qs');
	   axios.get(apiUrl+"user/listdetail?id="+userid).then(res => {
		   if(res.data.status == 'success'){
			   const formdata = res.data.userlist;
			 			
			   if(formdata.status === 'A'){
				   this.setState({selectedOption:{value: 'active', label: 'Active'}});
			   }else{
				   this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			   }
			   this.setState({username:formdata.username});
			   this.setState({firstname:formdata.firstname});
			   this.setState({lastname:formdata.lastname});
			   this.setState({email:formdata.email});
			   this.setState({phoneno:formdata.mobileno});
			   this.setState({userid: formdata.id});

		   }
		   
	   }); 
	   
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      /*if(name == 'password'){
      	this.setState({disablepasssword: false})
      }*/
    }
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){

		this.props.history.push('/');
		}
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'user/getdomainlist?admin_id='+admin_id)		
		.then(res => {
			if(res.data.status == 'success'){
				console.log(res.data);				
				this.setState({
					domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
				})				
			}else{
			//console.log("test")
			}
		});
    }

	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	 }
	
	
 handleFormSubmit = () => {
			console.log('aaaaaaaa');
			if(this.validateForm()){
				console.log('bbbbbbbb');
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					username     : formPayload.username,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					/*currentpswd  : formPayload.password,*/
					changepassword:formPayload.changepassword, 
					status       :  status,
					domain:formPayload.selecteddomain_value
				};
				axios.post(apiUrl+"user/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.userlist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/user');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, changepassword, user_confirm_password,selecteddomain} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		if (!username) {
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			$('.errorfirstname').html('');
		}

		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else if(lastname){
			$('.errorlastname').html('');
		}
		if (!email) {
			errors++;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}
		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		}else if(phoneno){
			$('.errorphoneno').html('');
		}

		if(changepassword !=''){
		 if (changepassword.match(/[a-z]/g) && changepassword.match( 
                    /[A-Z]/g) && changepassword.match( 
                    /[0-9]/g) && changepassword.match( 
                    /[^a-zA-Z\d]/g) && changepassword.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	errors++;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		
		// if(user_confirm_password !=''){
		//  if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
        //             /[A-Z]/g) && user_confirm_password.match( 
        //             /[0-9]/g) && user_confirm_password.match( 
        //             /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		//  				$('.errorchangepaswd').html('');
		//  }else{
		//  	errors++;
		// 	$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		//  }
		 
		// if(changepassword !== user_confirm_password){
        //   	errors++;
        //     $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        // }
		
		// }
		if (!selecteddomain) {
			errors++;
			$('.errordomain').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain){
			$('.errordomain').html('');
		}


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.userdetails !== this.props.userdetails){
    		if(Object.keys(Props.userdetails).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
				if(Props.userdetails[0].status === "success"){
					const formdata = Props.userdetails[0].userlist;						
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
					}
					this.setState({username:formdata.username});
					this.setState({firstname:formdata.firstname});
					this.setState({lastname:formdata.lastname});
					this.setState({email:formdata.email});
					this.setState({phoneno:formdata.mobileno});
					this.setState({userid: formdata.id});
					if(Props.userdetails[0].selecteddomain!==null && Props.userdetails[0].selecteddomain.length > 0){
						if(Props.userdetails[0].selecteddomain[0].value!== '' && Props.userdetails[0].selecteddomain.value!== null){
							this.setState({ selecteddomain : Props.userdetails[0].selecteddomain[0]})
						}
					}
				}
    		}
    	}

	
         if(Props.useredit !== this.props.useredit){
    		if(Object.keys(Props.useredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.useredit[0].status === "success"){

    			const formdata = Props.useredit[0].userlist;
				console.log(formdata);
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({userid: formdata.id});
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/user'));
						}
						.bind(this),
						3000
						);
    			}else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}   
      }




	
  render() {
	const domainArray = this.state.domainlist.map ((domainlist, index) => ({
		id: index,
		name: domainlist,
		}));
		
		// Dynamically create select list
		let domains = [];
		domainArray.map(item =>
			domains.push({ label: item.name.label, value: item.name.value }),
		);


  	const {selectedOption,selecteddomain} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="userlist" />  	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Phone number:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				{/*<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.chnagepassword}/>
					<div className="errorchangepaswd"></div>
				</div>*/}
				
			
				<div className="form-group eye-pad">
                    <label>Reset Password</label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>
				   
				</div>
			<div className="form-right">
				<div className="form-group">
					<label>First Name:</label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				
				<div className="form-group">					
					<label>Domain List : </label>
					<Select 
						options={domains}  
						value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
						onChange={this.handleChangeDomain}
						placeholder="Select Domain" />
					<div className="errordomain"></div>
				</div>

				{/* <div className="form-group eye-pad">
                    <label>Confirm Password</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                   <div className="errorchangepaswd"></div> */}


				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				

				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Edit);