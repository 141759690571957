import React, { Component } from 'react';
import { Link} from "react-router-dom";

import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from 'moment';
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      tutorlist:'',
      domainlist:[],
      universitynamelist:[],
      companylist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_domain:'',
    search_university:'',
    search_company: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
    });
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    checkstatus(id, status){
      let stat = ''
        if(status == 'Inactive'){
          stat = "A";
        }else{
          stat = "I";
        }
        $('.statusmessage').html('<span>Please wait...</span>');
            var qs = require('qs');
            var postObject = {
              admin_id : localStorage.getItem("admin_id"),
              loggedas : localStorage.getItem("loggedas"),
              tutor_id  :id,
              status: stat
            };
            axios.post(apiUrl+"tutor/change_status_tutor",qs.stringify(postObject)).then(res => {
              if(res.data.status == "success"){
                  $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
                    var qs = require('qs');
                    var admin_id = localStorage.getItem("admin_id");
                    var loggedas = localStorage.getItem("loggedas");
                    var postobject = {
                      activePage: 1,
                      search_all: '',
                      search_domain:'',
                      search_university:'',
                      search_company: '',
                      admin_id: admin_id,
                      loggedas: loggedas
                    };
                    axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
                      this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
                    });
                    setTimeout(
                      function() {
                        $('.success_message').html('');
                      }
                      .bind(this),
                      3000
                    );
                }
              });
          // this.props.getActivatetutor(qs.stringify(postObject));
    }

    inactivatetutor(id, status){
      confirmAlert({
       customUI: ({ onClose }) => {
         return (
           <div className='custom-ui'>
             <h1>Are you sure, Do you want to disapprove the account?</h1>
             <button onClick={onClose}>No</button>
             <button
               onClick={() => {
                  this.checkstatus(id, status)
                  onClose();
               }}
             >
               Yes
             </button>
           </div>
         );
       }
     });
     }

    activatetutor(id, status){
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure, Do you want to approve the account?</h1>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.checkstatus(id, status);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
    }

    componentDidMount() {

   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_domain: this.state.search_domain,
       search_university: this.state.search_university,
       search_company : this.state.search_company,
       admin_id: admin_id,
       loggedas:loggedas
     };
     axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
  });
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_domain : formPayload.search_domain,
    search_university : formPayload.search_university,
    search_company: formPayload.search_company,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"tutor/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
  });

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.tutorlist).length > 0) {
      if (Props.tutorlist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.tutorlist[0].records_count, tutorlist: Props.tutorlist[0]["tutorlist"] });
        this.tutorlist();
      }
    }


    if(Props.changestatustutor !== this.props.changestatustutor){
      if (Props.changestatustutor[0]["status"] === "success") { 
            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");
            var postobject = {
            activePage: 1,
            search_all: '',
            search_domain:'',
            search_university:'',
            search_company: '',
            admin_id: admin_id,
            loggedas: loggedas
            };
            axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
              this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
          });
            this.setState({
             //  totalRecord : Props.changestatustutor[0].records_count, tutorlist: Props.changestatustutor[0]["tutorlist"] 
            });
           
            
            $('.statusmessage').html('Status Changed Successfully');
            window.location.reload(); 
           this.tutorlist();
           this.tutorlist();
      }
    }

    if (Object.keys(Props.deletetutor).length > 0) {
      if (Props.deletetutor[0]["status"] === "success") {
        this.setState({
          tutorlist: Props.deletetutor[0]["tutorlist"],
        });
      }

      this.setState({statusmessage: Props.deletetutor[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
     
   }

   deletetutor(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };
    axios.post(apiUrl+"tutor/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");
            var postobject = {
              activePage: 1,
              search_all: '',
              search_domain:'',
              search_university:'',
              search_company: '',
              admin_id: admin_id,
              loggedas: loggedas
            };
            axios.post(apiUrl+"tutor/list",qs.stringify(postobject)).then(res => {
              this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
            });
            setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
            );
        }
      });

}

handleClickSelecet = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");

  var postObject = {             
    activePage   : 1,
    search_domain : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"tutor/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
});

  this.setState({
      search_domain: key,
      Loading:true     
  })

}

handleClickSelecetUniversity = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_university : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"tutor/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
});
  this.setState({
      Loading:true     
  })

}

handleClickSelecetCompany = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_company : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"tutor/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, tutorlist: res.data});
});

  this.setState({
      Loading:true     
  })

}

tutorlist() {
    var tutorlist = this.state.tutorlist;
    if (tutorlist != "undefined" && tutorlist != null) {
      if (tutorlist.status == "success") {
        if (Object.keys(tutorlist).length > 0) {
          const helperlistDetails = tutorlist.tutorlist.map(
            (tutorlist, Index) => {
              let sno = Index+1;
              if((tutorlist.u_name !== '') && (tutorlist.u_name !== null)){
                var u_name = tutorlist.u_name;
              }else{
                  u_name = 'N/A';
              }

              if((tutorlist.f_name != '') && (tutorlist.f_name != null)){
                var f_name = tutorlist.f_name;
              }else{
                  f_name = 'N/A';
              }

              //if(tutorlist.status == 'A'){
                var status = 'Active';
              // }else{
              //     status = 'Inactive';
              // }
              var activebtn = 'no';
              if(localStorage.getItem('loggedas') === 'admin'){
                var activebtn = 'yes';
              }
              if(localStorage.getItem('loggedas') === 'kollel'){
                if(tutorlist.university_name=== null && tutorlist.kollel!== null){
                  var activebtn = 'yes';
                }
              }
              if(localStorage.getItem('loggedas') === 'yeshiva'){
                if(tutorlist.university_name=== null && tutorlist.kollel!== null){
                  var activebtn = 'no';
                }else if(tutorlist.university_name!== null && tutorlist.kollel!== null){
                  var activebtn = 'yes';
                }
              }

              var istyle = { width: 100};
              return (
                <tr key={tutorlist.id}>
      					<td>{sno}</td>  
      					<td>{tutorlist.tutor_name}</td>
                {/* <td>{tutorlist.username}</td> */}
                <td>{tutorlist.email}</td>
                <td>{tutorlist.mobileno}</td>
                {localStorage.getItem('admin_id') === '1' && <td>{tutorlist.org_password}</td> }
                <td>{(tutorlist.domain_name) ? tutorlist.domain_name : "N/A"}</td>
                {/*<td>{tutorlist.school_name}</td>*/}
                {/* {localStorage.getItem('loggedas') != 'kollel' &&
                <td>{u_name}</td>} */}
                {/* <td>{f_name}</td>
                <td>{tutorlist.settings_site_title}</td>   */}
                <td>{moment(tutorlist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{status}</td>                
                <td className="actiongroup" >
                    
                    {(localStorage.getItem('loggedas') === 'admin') || (localStorage.getItem('loggedas') === 'kollel')?
                      <> 
                    <Link to={{ pathname: '/tutor-view/'+tutorlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    {/* <Link to={{ pathname: '/tutor-edit/'+tutorlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link> */}
                     <Link onClick={this.deletetutor.bind(this,tutorlist.id)} className="" title="Duplicate"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                     </>:<></>
                    }
                    {/* {(localStorage.getItem('loggedas') !== 'admin')?
                      <> */}
                      {/*(activebtn ==='yes')?
                        <>
                         {(status == 'Active') ?<Link onClick={this.inactivatetutor.bind(this, tutorlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activatetutor.bind(this, tutorlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>} 
                         </>:<>
                        </>
                    */}
                      {/* </>:
                      <> 
                      </>

                    } */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="tutor" /> 
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">
              <div className="listing-header">
                <div className="title">
                  <h3>Melamed</h3>
                </div>
               {/* <div className="select-1">
                { admin_id == '1'?
              <div className="form-group">
                <select  onChange={current.handleClickSelecetUniversity}  value={this.state.search_university}>                    
                {this.state.universitynamelist.map((universitynamelist) => <option key={universitynamelist.value} data-key={universitynamelist.value} >{universitynamelist.label}</option>)}
                </select>
              <div className="errorassign_university"></div>
              </div> : ''}
              </div>
              <div className="select-2">
                 {admin_id == '1'?	
              <div className="form-group">
                <select  onChange={current.handleClickSelecetCompany}  value={this.state.search_company}>                    
                {this.state.companylist.map((companylist) => <option key={companylist.value} data-key={companylist.value} >{companylist.label}</option>)}
                </select>
              <div className="errorassign_comapny"></div>
              </div> : ''}
              </div> */}

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {localStorage.getItem('loggedas') === 'admin' && <div className="add_button">  
                    <a className="add" href='/tutor-add'>Add</a>
                </div>}
                {localStorage.getItem('loggedas') === 'kollel' && <div className="add_button">  
                    <a className="add" href='/tutor-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/tutor-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a>
                </div>}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Melamed Name</th>
                  {/* <th>Username</th> */}
                  <th>Email</th>
                  <th>Contact</th>
                  {localStorage.getItem('admin_id') === '1' && <th>Password</th> }
                  <th>Domain</th>
                  {/*<th>Kollel</th>*/}
                  {/* {localStorage.getItem('loggedas') != 'kollel' &&
                  <th>Yeshiva</th>} */}
                  <th>Registered On</th>
                  {/* <th>Funding</th>
                  <th>Companies</th> */}
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.tutorlist()}</tbody>
                </Table>
				</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;