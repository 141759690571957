import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import moment from 'moment';

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const studentid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    
    this.state = {      
      studentid: studentid,
      compaignview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :studentid
    };
    axios.post(apiUrl+"compaign/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
       
          this.setState({compaignview:res.data.compaignview[0]}) 
        
      }	
		});
  }

   
   componentDidMount() {
     document.title = PageTitle('Campaign View');
   }


  
  render() {

    var startdate = moment(this.state.compaignview.start_date).format('MM/DD/YYYY');
    var enddate = moment(this.state.compaignview.end_date).format('MM/DD/YYYY');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="compaign" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Campaign Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Campaign Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.compaign_name}</p>
                        </div>
                      </div>
                    
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Campaign Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.email}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.compaign_location !== null && this.state.compaignview.compaign_location !== '' ? this.state.compaignview.compaign_location : 'NA'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Shules Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.shules_name != null ?  this.state.compaignview.shules_name: '-'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Campaign Start Date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{startdate}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Campaign End Date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{enddate}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Goal Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.compaignview.goal_limit_amount !== null && this.state.compaignview.goal_limit_amount !== '' && this.state.compaignview.goal_limit_amount !== '0' ? this.state.compaignview.goal_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Expected Goal<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.compaignview.goal_amount !== null && this.state.compaignview.goal_amount !== '' && this.state.compaignview.goal_amount !== '0' ? this.state.compaignview.goal_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Acheived Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.compaignview.achieved_amount !== null && this.state.compaignview.achieved_amount !== '' && this.state.compaignview.achieved_amount !== '0' ? this.state.compaignview.achieved_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.short_description !== null && this.state.compaignview.short_description !== '' ? this.state.compaignview.short_description : 'NA' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.description !== null && this.state.compaignview.description !== '' ? this.state.compaignview.description : 'NA' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.compaignview.status == 'A' ? 'Active' : 'Inactive'}</p>
                        </div>
                      </div>
                      
                  </div>
                </div>
                    
                  

                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    </div>
    );
  }
}

export default withRouter(View);