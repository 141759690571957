import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import axios from 'axios';
import { apiUrl } from '../Config/Config';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
     const shulesid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
   
    this.state = {      
      shulesid: shulesid,
      shulesview:'',
      userlist: [],
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

    var qs = require('qs');
    var postObject = {             
    id   :shulesid
    };
    axios.post(apiUrl+"shules/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        if(res.data.status == "success"){
          this.setState({shulesview:res.data.shulesview[0]}) 
        }
      }	
		});
  }

   
   componentDidMount() {
    var qs = require('qs');
    var postObject = {
      shulesid:this.state.shulesid
    };
    axios.post(apiUrl+"shules/getSupportersByShules",qs.stringify(postObject)).then(res => {
      if(res.data.status === "success"){	
        this.setState({userlist: res.data.result_set, totalRecord : res.data.result_set.records_count});
      }
    });
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var qs = require('qs');
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: localStorage.getItem('admin_id')  
     };
   this.props.getUserList(qs.stringify(postobject))
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.shulesview!==this.state.shulesview){
        if(Props.shulesview[0].status == "success"){
          this.setState({shulesview: Props.shulesview[0].shulesview[0]}) 
        }
      }
   }

   userlist() {
	  console.log(this.state.userlist,"userlist")
    var userlist = this.state.userlist;
    if (userlist != "undefined" && userlist != null) {
        if (Object.keys(userlist).length > 0) {
          const userlistDetails = userlist.map(
            (userlist, Index) => {
              let sno = Index+1;
              if(userlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={userlist.id}>
                <td>{sno}</td>
				 {/* <td>
                  {userlist.username}
                  </td> */}
                  <td>
                  {userlist.firstname}
                  </td>
                  <td>{userlist.email}</td>
				          <td>{userlist.mobileno}</td>
				         {/* <td>{userlist.domain_name}</td> */}
                 <td>{moment(userlist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                </tr>
              );
            }
          );
          return userlistDetails;
        }else {
          return (
            <tr>
              <td colSpan="9" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
    }
  }


  
  render() {

    // var startdate = moment(this.state.shulesview.start_date).format('MM/DD/YYYY');
    // var enddate = moment(this.state.shulesview.end_date).format('MM/DD/YYYY');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="shules" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Shules Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Shules Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.shules_name}</p>
                        </div>
                      </div>
                    
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Shules Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.email}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.shules_location !== null && this.state.shulesview.shules_location !== '' ? this.state.shulesview.shules_location : 'NA'}</p>
                        </div>
                      </div>
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Shules Start Date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{startdate}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Shules End Date<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{enddate}</p>
                        </div>
                      </div> */}
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Goal Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.shulesview.goal_limit_amount !== null && this.state.shulesview.goal_limit_amount !== '' && this.state.shulesview.goal_limit_amount !== '0' ? this.state.shulesview.goal_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Expected Goal<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.shulesview.goal_amount !== null && this.state.shulesview.goal_amount !== '' && this.state.shulesview.goal_amount !== '0' ? this.state.shulesview.goal_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Acheived Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${this.state.shulesview.achieved_amount !== null && this.state.shulesview.achieved_amount !== '' && this.state.shulesview.achieved_amount !== '0' ? this.state.shulesview.achieved_amount : '0' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.short_description !== null && this.state.shulesview.short_description !== '' ? this.state.shulesview.short_description : 'NA' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.description !== null && this.state.shulesview.description !== '' ? this.state.shulesview.description : 'NA' }</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.shulesview.status == 'A' ? 'Active' : 'Inactive'}</p>
                        </div>
                      </div>
                      
                  </div>
                </div>
                    
                <div className="car-listing-row">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        {/* <th>User Name</th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                      <th>Registered On</th>
                       
                      </tr>
                    </thead>
                    <tbody>{this.userlist()}</tbody>
                  </Table>
                  <div className="text-center">
                    <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalRecord}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </div>

                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    </div>
    );
  }
}
export default withRouter(View);