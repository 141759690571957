import React, { Component } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl, } from'../Config/Config';
import axios from 'axios';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
};
class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
            domain_id:'',
			name:'',
			username:'',			
			age:'',
			university_name:'',
			school_name:'',
			email:'',
			mobileno:'',
			dob:'',
			dob_change: new Date(),
			donation_start_date:'',
			donation_end_date:'',
			parent_name:'',
			parent_mobileno:'',
			address:'',
			student_story:'',
			amount:'',
			donation_limit_amount:'',
			status:'',
			fund_name:'',
			user_password: '',
            user_confirm_password: '',
			new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			domainlist:[],
			universitynamelist:[],
			fundnamelist:[],
			statusmessage:'',
			Loading:false,
			kollelnamelist:[],
			selectedKollel:'',
			/* kollelvalue:localStorage.getItem('admin_id'),	 */		
			kollelvalue:'',	
			domainlist:[],
			selected_domain:'',
			no_of_kids:'',
			learning_hours:'',
			selecteddomain:'',
			selecteddomain_value:''
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

		axios.get(apiUrl+'student/getkollelnamelist?admin_id='+localStorage.getItem("admin_id"),).then(res => {
			if(res.data.kollelnamelist!== '' && res.data.kollelnamelist!== null){
				this.setState({
					kollelnamelist: [{value: '', label: 'Select Kollel'}].concat(res.data.kollelnamelist)
				})
			}
		});
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	}

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		const google = window.google;
		const callfunc = this;

			/*if(name == 'address'){
				var input = document.getElementById('place_event');
				var autocomplete = new google.maps.places.Autocomplete(input);
				autocomplete.addListener('place_changed',function() {
				document.getElementById("location-error").style.display = 'none';
				var place = autocomplete.getPlace();
				var locationAddress1 = place.name;
				var locationAddress = place.formatted_address;
				var fullplace = place.name+','+locationAddress;
				callfunc.setState({address: locationAddress})
				callfunc.getgoecoder(locationAddress,input.name);
				callfunc.setState({fulldroploc : fullplace});
				});
			}*/
    }

	getgoecoder(input, name){

		$('#place_event').val('');
			
		if(name == 'address'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					address: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}



	componentDidMount() {
		

		document.title = PageTitle('Student Add');
		if(localStorage.getItem('admin_id') === null){
			 this.props.navigate('/');
		}

		var admin_id = localStorage.getItem('admin_id');
		var loggedas = localStorage.getItem('loggedas');
			axios.get(apiUrl+'student/getdomainlist?admin_id='+admin_id+'&loggedas='+loggedas)			
			.then(res => {
				if(res.data.status == 'success'){	
					this.setState({
						domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
					})				
				}else{
				//console.log("test")
				}
			});

			axios.get(apiUrl+'student/getuniversitynamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){				
					this.setState({
						universitynamelist: [{value: '', label: 'Select Yeshiva'}].concat(res.data.universitynamelist)
					})				
				}else{
				    //console.log("test")
				}
			});
			axios.get(apiUrl+'student/getfundnamelist?admin_id='+admin_id)			
			.then(res => {
				if(res.data.status == 'success'){
					this.setState({
						fundnamelist: [{value: '', label: 'Select Fund'}].concat(res.data.fundnamelist)
					})
				}
			});
    }

	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}
		
		confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
		} 


        handleFormSubmit = () => {
			
			if(this.validateForm()){
				
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
                    // domain_id : formPayload.domain_id,
					name: formPayload.name,
					username:formPayload.username,
                    age: formPayload.age,
					password: formPayload.user_password,
					confirm_password:formPayload.user_confirm_password,					
					/* university_name:formPayload.university_name, */
					school_name: formPayload.school_name,
					mobileno:formPayload.mobileno,
					dob:formPayload.dob,
					dob_change:formPayload.dob_change,
					donation_start_date:formPayload.donation_start_date,
					donation_end_date:formPayload.donation_end_date,
					email:formPayload.email,
					parent_name: formPayload.parent_name,
					parent_mobileno: formPayload.parent_mobileno,
					/*address: formPayload.address,
					from_lat:formPayload.from_lat,
					from_long:formPayload.from_long,*/
					student_story: formPayload.student_story,
					no_of_kids: formPayload.no_of_kids,
					learning_hours: formPayload.learning_hours,
					amount: formPayload.amount,
					donation_limit_amount: formPayload.donation_limit_amount,
					status:  status,
					fund_name: formPayload.fund_name,
					kollel : (localStorage.getItem('loggedas') == "kollel" ? localStorage.getItem('admin_id') : formPayload.kollelvalue),
					loggedas:localStorage.getItem('loggedas'),
					domain: formPayload.selecteddomain_value //formPayload.selected_domain
				};
				// this.props.getAddStudent(qs.stringify(postObject));
				axios.post(apiUrl+"student/add",qs.stringify(postObject)).then(res => {
					if(res.data.status === 'success'){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							this.setState({
								Loading:false
							});
							 setTimeout(
							 function(){							
								 $('.success_message').html('');
								 this.props.navigate('/student');
								}
							.bind(this),
							3000
							);
						}else{
						 this.setState({Loading:false});
							$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
				});
				/*.then(res => {
					if(res.data.status === "success"){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html(this.props.history.push('/student'));
							}
							.bind(this),
							3000
						);
					}else if(res.data.status === "error"){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							7000
						);
						this.setState({Loading:false});
					}

				});*/

			}
	}

	validateForm() {
		const {selected_domain,name,age,username,user_password,dob,donation_start_date,donation_end_date,user_confirm_password,school_name,email,parent_name,parent_mobileno,mobileno,domain_id,address,student_story,amount,donation_limit_amount,kollelvalue} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!name) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}
		// if (!selected_domain) {
		// 	formIsValid = false;
		// 	$('.errordomain').html('<span class="errorspan">Please fill the field</span>');
		// }else if(selected_domain){
		// 	$('.errordomain').html('');
		// }
	
       /*  if(!university_name && !kollelvalue){
        	formIsValid = false;
        	$('.errorkollel').html('<span class="errorspan">Please choose any one of the type Yeshiva/Kollel</span>');
        	$('.erroruniversity_name').html('<span class="errorspan">Please choose any one of the type Yeshiva/Kollel</span>');
		}else{
			$('.errorkollel').html('');
			$('.erroruniversity_name').html('');
		} */
		if(!kollelvalue && localStorage.getItem('loggedas') != 'kollel'){
        	formIsValid = false;
        	$('.errorkollel').html('<span class="errorspan">Please choose any one of the Kollel</span>');
		}else{
			$('.errorkollel').html('');
		}
        
     

		if (!username) {
			$('.errorusername').html('<span class="errorspan">Please select the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}
		
		if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		if (!dob) {
			formIsValid = false;
			$('.errordob').html('<span class="errorspan">Please fill the field</span>');
		}else if(dob){
			$('.errordob').html('');
		}

		if (!donation_start_date) {
			formIsValid = false;
			$('.errordonation_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_start_date){
			$('.errordonation_start_date').html('');
		}

		if (!donation_end_date) {
			formIsValid = false;
			$('.errordonation_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_end_date){
			$('.errordonation_end_date').html('');
		}

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

	
		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			$('.erroramount').html('');
		}

		if (!donation_limit_amount) {
			formIsValid = false;
			$('.errordonation_limit_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_limit_amount){
			$('.errordonation_limit_amount').html('');
		}

		if (!user_password) {
			// errors++;
			formIsValid = false;
			$('.errorpassword').html('<span class="errorspan">Please fill the field</span>');
		}else if(user_password){
		 if (user_password.match(/[a-z]/g) && user_password.match( 
                    /[A-Z]/g) && user_password.match( 
                    /[0-9]/g) && user_password.match( 
                    /[^a-zA-Z\d]/g) && user_password.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
			formIsValid = false;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		if (!user_confirm_password) {
			formIsValid = false;
			$('.errorchangepaswd').html('<span class="errorspan">Please fill the confirm password</span>');
		}else if(user_confirm_password){
		 if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
                    /[A-Z]/g) && user_confirm_password.match( 
                    /[0-9]/g) && user_confirm_password.match( 
                    /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
			formIsValid = false;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		if(user_password !== user_confirm_password){
			formIsValid = false;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }

	/*	if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}*/

       
		/*if (!fund_name) {
			formIsValid = false;
			$('.errorfund_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fund_name){
			$('.errorfund_name').html('');
		}*/

		

		this.setState({
			errors: errors
		});
		return formIsValid;
    }



/* 	handleClickSelecetUniversity = (event) => {
		if(this.state.kollelvalue !== ''){

			$('.erroruniversity_name').html('<span class="errorspan">You already assigned to kollel</span>');
			setTimeout(
				function() {
					$('.erroruniversity_name').html('');
				}
				.bind(this),
				3000
				);
		}else{

		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				university_name: keys,	
		})

		}
	} */

	handleClickSelecetFund = (event) => {

		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
			fund_name: keys,
				
		})
	}
	handleClickSelecetdomain = (event) =>{
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
		
		this.setState({
			selected_domain: keys,
				
		})
	}

    componentWillReceiveProps(Props){
   	if(Props.studentadd !== this.props.studentadd){
    		if(Object.keys(Props.studentadd).length > 0){
				this.setState({Loading:false});

					if(Props.studentadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.studentadd[0].message+'</h3></div>');
					
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/student');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.studentadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						5000
						);
					}

    		}
    	}
    		if(Props.kollelnamelist.length > 0){
				if(Props.kollelnamelist[0].kollelnamelist!== '' && Props.kollelnamelist[0].kollelnamelist!== null){
					this.setState({
						kollelnamelist: [{value: '', label: 'Select Kollel'}].concat(Props.kollelnamelist[0].kollelnamelist)
					})
				}
			}
    }

	onChangedatetime1 = (date) => { 

		this.setState({ dob_change: date })
	   this.setState({ dob: date })
	}

	onChangedatetime2 = (date) => { 

	this.setState({ donation_start_date: date })
	}

	onChangedatetime3 = (date) => { 

	this.setState({ donation_end_date: date })
	}		
	
  handleChangeKollel = (event) => {
		
			const selectedIndex = event.target.options.selectedIndex;
		    var keys = event.target.options[selectedIndex].getAttribute('data-key');
		
			this.setState({
			kollelvalue: keys,
			});
   }

  render() {

	let preview = '';
	const {fundnamelist,kollelnamelist,selectedKollel,selecteddomain} = this.state;
	let domain_list = [{ label: 'shiviti.co', value: 82 }];
	const domainArray = domain_list.map ((domainlist, index) => ({
	id: index,
	name: domainlist,
	}));
	
	// Dynamically create select list
	let domains = [];
	domainArray.map(item =>
		domains.push({ label: item.name.label, value: item.name.value }),
	);
/*	console.log(kollelnamelist)
	const kollelArray = kollelnamelist.map ((kollellist, index) => ({
	id: index,
	name: kollellist,
	}));
	// Dynamically create select list
	let kolleloption = [];
	kollelArray.map(item =>
	kolleloption.push({ label: item.name.label, value: item.name.value }),
	);
	console.log(kolleloption)*/
	var current = this;
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="student" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Arvech</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">

				{localStorage.getItem('loggedas') != 'kollel' &&
				 <div className="form-group">					
					<label>Kollel<span class="required" style={{ color: "red" }} > * </span> : </label>
						<select  onChange={this.handleChangeKollel} className='form-control'>                    
						{this.state.kollelnamelist.map((kollelnamelist) => <option key={kollelnamelist.value} data-key={kollelnamelist.value} value={kollelnamelist.value} >{kollelnamelist.label}</option>)}
						</select>
					<div className="errorkollel"></div>
				</div>}

				<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>

				{/*<div className="form-group">
					<label>Age:</label>
				    <input type="text" name="age" onChange={this.handleInputChange} className="form-control" value={this.state.age} />
					<div className="errorage"></div>
				</div>*/}



				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>

				{/*<div className="form-group">
					<label>Kollel<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="school_name" onChange={this.handleInputChange} className="form-control" value={this.state.school_name} />
					<div className="errorschool_name"></div>
				</div>*/}

				<div className="form-group">
					<label>Goal ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				<div className="form-group">
					<label>Parent Name:</label>
						<input type="text" name="parent_name" onChange={this.handleInputChange} className="form-control" value={this.state.parent_name} />
					<div className="errorparent_name"></div>
				</div>
				
				<div className="form-group">
					<label>Fund Category: </label>
				    <select  className='form-control' onChange={current.handleClickSelecetFund}  value={fundnamelist.f_name}>                    
                    {this.state.fundnamelist.map((fundnamelist) => <option key={fundnamelist.value} data-key={fundnamelist.value} value={fundnamelist.value} >{fundnamelist.label}</option>)}
                    </select>
					<div className="errorfund_name"></div>
				</div>

				<div className="form-group eye-pad">
                    <label>New Password:</label>
                    <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
					
			     </div>
				
				<div className="form-group">					
					<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_start_date}
				        onChange={this.onChangedatetime2}
				        dateFormat="MM/dd/yyy"
                        showMonthDropdown
                        showYearDropdown
			 			value={this.state.donation_start_date}
						className='form-control'
				        />
					<div className="errordonation_start_date"></div>
					
				</div>
			
				<div className="form-group">
					<label>Number Of Kids:</label>
						<input type="text" name="no_of_kids" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_kids} />
					<div className="errorno_of_kids"></div>
				</div>
	   <div className="form-group">					
					<label>About Student:</label>
						<textarea type="text" name="student_story" onChange={this.handleInputChange} className="form-control" value={this.state.student_story}  />
					<div className="errorstudent_story"></div>
				</div>
				</div>
			
			<div className="form-right">
			<div className="form-group">
					<label>Username<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} />
					<div className="errorusername"></div>
				</div>

				<div className="form-group">					
					<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
					<div className="errormobileno"></div>
				</div>

				<div className="form-group">					
					<label>Date Of Birth<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
						className='form-control'
				        selected={this.state.dob_change}
				        onChange={this.onChangedatetime1}
				        dateFormat="MM/dd/yyy"
				        showMonthDropdown
                        showYearDropdown
						maxDate={new Date()}
						value={this.state.dob}
				        />
					<div className="errordob"></div>
					
				</div>	

				{/*<div className="form-group">					
					<label>Kollel Location:</label>
						<input type="text" id="place_event" name="address" placeholder="Enter a location" onChange={this.handleInputChange} className="form-control" value={this.state.address} />
					<div className="erroraddress"></div>
				</div>*/}

				<div className="form-group">
					<label>Goal Limit($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
					<div className="errordonation_limit_amount"></div>
				</div>

				<div className="form-group">
					<label>Parent Mobile Number:</label>
						<input type="text" name="parent_mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.parent_mobileno} />
					<div className="errorparent_mobileno"></div>
				</div>

				
				   {/* {localStorage.getItem('loggedas') != 'kollel' &&
				   <div className="form-group">
					<label>Yeshiva<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetUniversity}  value={universitynamelist.university_name}>                    
                    {this.state.universitynamelist.map((universitynamelist) => <option key={universitynamelist.value} data-key={universitynamelist.value} value={universitynamelist.value} >{universitynamelist.label}</option>)}
                    </select>
					<div className="erroruniversity_name"></div>
				 </div>} */}
				
				<div className="form-group">
					<label>Domain List: </label>
				    <Select 
					options={domains}  
					value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
					onChange={this.handleChangeDomain}
					placeholder="Select Domain" />
					<div className="errordomain"></div>
				 </div>

				 <div className="errorpassword"></div>
                
				 <div className="form-group eye-pad">
                    <label>Confirm Password:</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                <div className="errorchangepaswd"></div>
				<div className="form-group">					
					<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_end_date}
				        onChange={this.onChangedatetime3}
				        dateFormat="MM/dd/yyy"
				        showMonthDropdown
                        showYearDropdown
						value={this.state.donation_end_date}
						className='form-control'
				        />
					<div className="errordonation_end_date"></div>
					
				</div>

				<div className="form-group">
					<label>Learning Hours:</label>
						<input type="text" name="learning_hours" onChange={this.handleInputChange} className="form-control" value={this.state.learning_hours} />
					<div className="errorlearning_hours"></div>
				</div>
               {/* <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> */}

			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}

export default withRouter(Add);