import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import CKEditor from 'ckeditor4-react';
//import {GET_VEHICLETYPE, GET_ADDCAR }  from '../../actions'; 


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
  		
		
class Edit extends Component {	
	fileObj = [];
    fileArray = [];
    imageArray = [];	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const adId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state =  {
		adId: adId,
		ad_name:'',
		ad_description:'',			
		count:'',	
		count:'',
		selectedvalue: '',
		selectedOption:{ value: 'active', label: 'Active'},
		selectedsizevalue: '',
		selectedsizeOption:{ value: '350x350', label: '350x350'},
		user_image: [],		
		user_image_name: [],
		user_image_preview: [],
		image: [],
		icon_preview : '',
		isHidden: false,
		statusmessage:'',		
		Loading:false,
		fileName: [],
		thumbDefault: null,
		priority: "",			
		selectedCateOption: '',
		selectedCatevalue:'',
		selectedDurationOption:'',
		selectedDurationvalue:'',
		admin_id: localStorage.getItem('admin_id'),
		enableuploadgalley: false,
		imagelist:'',
		selectedimages:[],
		galleryimg: 'No',		
		durationlist:[{label:'Year',value:'Year'},
			{label:'Month',value:'Month'}],	
		typelist:[{label:'Range',value:'range'},
			{label:'Count',value:'count'},
			{label:'Both',value:'both'},
		],	
		image_preview:'',
		image_name:'',
		image:'',
		range:'',		
		to_range:'',
	}		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
    }


	listloaddata=()=>{
		var qs = require('qs');
		axios.get(apiUrl+"awardmanagement/listdetail?id="+this.state.adId).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.adslist;	
				this.setState({editid:1,image_preview:res.data.adslist.images,old_image:res.data.adslist.images})
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}
				this.setState({ad_name:formdata.name,ad_description:formdata.description,
					ad_description:formdata.description,
					
					range:formdata.range,
					to_range:formdata.to_range,
					count:formdata.count,
					to_count:formdata.to_count,
					old_tab_icon:formdata.images
				});	
				if(formdata.duration=='Month'){
					this.setState({selectedDurationOption:{label:'Month',value:'Month'}})
					this.setState({  selectedDurationvalue :'Month'});
				}if(formdata.duration=='Year'){
					this.setState({selectedDurationOption:{label:'Year',value:'Year'}})
					this.setState({  selectedDurationvalue :'Year'});
				}	

				if(formdata.type=='range'){
					this.setState({selectedCateOption:{label:'Range',value:'range'}})
					this.setState({  selectedCatevalue :'range'});
				}if(formdata.type=='count'){
					this.setState({selectedCateOption:{label:'Count',value:'count'}})
					this.setState({  selectedCatevalue :'count'});
				}if(formdata.type=='both'){
					this.setState({selectedCateOption:{label:'Both',value:'both'}})
					this.setState({  selectedCatevalue :'both'});
				}	
			}	
		}); 
	}

	handleChange = (r,selOption) => {
        //this.setState({selectedOption})
        //this.setState({  selectedvalue : selectedOption.value});
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'type'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}else if(r == 'duration'){			
			this.setState({selectedDurationOption:selOption})
			this.setState({  selectedDurationvalue : selOption.value});
		}
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });		
    }
	onEditorChange( evt ) {
		var callfunc = this;
		setTimeout(function () {
		this.setState( {
			template_content: evt
		} );
		}.bind(this),1000);
 
	}
	// onEditorChange( evt ) {
    // 	// console.log(evt.editor.getData())
    // 	// var callfunc = this;
    // 	// setTimeout(function () {
	// 	// callfunc.setState( {
    //     //     terms_and_condition: evt.editor.getData()
    //     // } );
	// 	// }, 1000);
	// 	localStorage.setItem('tab_content',evt.editor.getData())     
    // }

	componentDidMount() {
		document.title = PageTitle('Awards Add');
		this.listloaddata();
    }	

   	handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value ==''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }

      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false})
      }
   }


onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  
uploadMultipleFiles = (e) =>{

		this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ user_image: this.imageArray });			
	
    }
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status, cate , modue_typeIs,duration= '';

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.module_type_value === '' && formPayload.module_type_value!== 0){
					modue_typeIs =formPayload.module_type.value;
					}else{
						modue_typeIs = formPayload.module_type_value;
					}

				if(formPayload.selectedCatevalue === '' && formPayload.selectedCatevalue!== 0){
					cate =formPayload.selectedCateOption.value;
				}else{
					cate = formPayload.selectedCatevalue;
				}

				if(formPayload.selectedDurationvalue === '' && formPayload.selectedDurationvalue!== 0){
					duration =formPayload.selectedDurationOption.value;
				}else{
					duration = formPayload.selectedDurationvalue;
				}
				
				var imgSize = '';
				if(formPayload.selectedsizevalue === ''){
				 imgSize =formPayload.selectedsizeOption.value;
				}else{
				 imgSize = formPayload.selectedsizevalue;
				}
                
                var galleryimg = 'No';
				if(Object.keys(formPayload.selectedimages).length >0){
                   galleryimg = 'Yes';
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					name: formPayload.ad_name,
					description:formPayload.ad_description,					
					count:formPayload.count,
					to_count:formPayload.to_count,
					status      :status,			
					uploadfromgallery : galleryimg,					
					type:cate,			
					duration:duration,			
					range:formPayload.range,	
					to_range:formPayload.to_range,
					old_tab_icon : this.state.old_tab_icon,		
					id : formPayload.adId,
				};


				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}

                /*var filesdata;
                if(galleryimg == 'No'){
                    filesdata = this.state.user_image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }*/
				
				
				/*for (var i in filesdata) {
				 formData.append('ad_image[]',filesdata[i])
				}*/

				var icondata = formPayload.icon;
				formData.append('ad_image',icondata)	
							
				
				axios.post(apiUrl+"awardmanagement/edit",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						  
						 
						   scrollToTop();
						   setTimeout(
							function() {
								
								$('.success_message').html('');
							this.props.navigate('/awards');
							}
							.bind(this),
							3000
							);
							localStorage.removeItem('tab_content')
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   scrollToTop();
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  });
			}
	}

	validateForm() {
		const {ad_name,ad_description,range,to_range,count,to_count,selectedCatevalue,selectedDurationvalue} = this.state;
		//var ad_description = localStorage.getItem("tab_content");
		let errors = 0;
	const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb
		if (!ad_name) {
			errors++;
			$('.errorad_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_name){
			$('.errorad_name').html('');
		}
		if(selectedCatevalue=='both'||selectedCatevalue=='range')
		{
			if (!range) {
				errors++;
				$('.errorrange').html('<span class="errorspan">Please fill the field</span>');
			}else if(range){
				$('.errorrange').html('');
			}

			if (!to_range) {
				errors++;
				$('.errorto_range').html('<span class="errorspan">Please fill the field</span>');
			}else if(to_range){
				$('.errorto_range').html('');
			}
		}		
		if(selectedCatevalue=='both'||selectedCatevalue=='count')
		{
			if (!count) {
				errors++;
				$('.errorcount').html('<span class="errorspan">Please fill the field</span>');
			}else if(count){
				$('.errorcount').html('');
			}
			
			if(!to_count){
				errors++;
				$('.errorto_count').html('<span class="errorspan">Please fill the field</span>');
			}else if(to_count){
				$('.errorto_count').html('');
			}
		}

		if (!ad_description) {
			errors++;
			$('.errorad_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_description){
			$('.errorad_description').html('');
		}

		 
		if (!selectedDurationvalue) {
			errors++;
			$('.errorduration').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedDurationvalue){
			$('.errorduration').html('');
		}

		if (!selectedCatevalue) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedCatevalue){
			$('.errortype').html('');
		}
	
		

		


		if(errors>0) { 
			setTimeout(function () {
			scrollToTopValidate();
			}, 100);
			return false;
		}
		else { 
			return true;
		}

    }

    
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		// var thumbDefault = this.state.thumbDefault;
		// if(indexvalue <= thumbDefault){
		// 	let thum = thumbDefault-1;
		// 	 if(thum < 0 ){
		// 	   this.setState({thumbDefault : 0})
		// 	 }else{
		// 		this.setState({thumbDefault : thum});
		// 	}    
		// }
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			//var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						//var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							// if(thumbDefaultArr != null && thumbDefaultArr == index){
							// 	indicheckFlag = true;
							// }
						//}
						  return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	//var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			//var indicheckFlag = false;
		 				// if(thumbDefaultArr != null && thumbDefaultArr == index){
						// 	indicheckFlag = true;
						// } 
						  return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={mediaUrl+'/awardmanagement/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }


 getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }

   handlefieldTypeChange(event) {   
	this.setState({module_type:[event],module_type_value:event.value})
}

handlefieldoffersTypeChange(event) {   
	this.setState({offer_page_type:[event],offer_page_type_value:event.value})
}
handlefieldShuleChange(event) {   
	this.setState({selectedShule:[event],selectedShuleValue:event.value});
}

  
  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		icon_name: file.name,
		icon_preview: reader.result,
		icon: file,
		icon_fileExtension:extension,
	  });
	};
	reader.readAsDataURL(file);	
  } 
  render() {
  	let imagesrc = '';	
  	const {selectedOption,selectedCateOption,selectedCatevalue,selectedDurationOption,selectedLinkOption, user_image_preview} = this.state;
	  var	preview = (this.state.icon_preview) ? 
	  <img className="img_class" src={this.state.icon_preview} alt="" /> :
	  <img className="img_class" src={mediaUrl+'awards/'+this.state.old_tab_icon} alt="" />
	  ;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="awards" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="car-listing-wrap leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/awards">Back</a>
			</div>
			<div className="title">
				<h4>Edit Awards</h4>
			</div>
			<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
				<div className="form-row">
					<div className="form-left">
						<div className="form-group">
							<label>Name<span class="required" style={{ color: "red" }} >*</span>:</label>
							<input type="text" name="ad_name" onChange={this.handleInputChange} className="form-control" value={this.state.ad_name} />
							<div className="errorad_name"></div>
						</div>

						<div className="form-group">					
							<label>Duration<span class="required" style={{ color: "red" }} > * </span>:</label>
							<Select 
							value={selectedDurationOption?selectedDurationOption:""}
							options={this.state.durationlist}  
								onChange={this.handleChange.bind(this, 'duration')}
							/>
							<div className="errorduration"></div>
						</div>

						<div className="form-group">					
							<label>Type<span class="required" style={{ color: "red" }} > * </span>:</label>
							<Select 
							value={selectedCateOption?selectedCateOption:""}
							options={this.state.typelist}  
								onChange={this.handleChange.bind(this, 'type')}
							/>
							<div className="errortype"></div>
						</div>

						<div className="form-group">					
							<label>Description<span class="required" style={{ color: "red" }} >*</span>:</label>
							<CKEditor
								editor={ ClassicEditor }
								data={this.state.ad_description}
								onReady={ editor => {
									// You can store the "editor" and use when it is needed.
									console.log( 'Editor is ready to use!', editor );
									editor.ui.view.editable.element.style.minHeight = "300px";
								} }
								name="ad_description"
								// onChange={this.handleInputChange}
								onChange={ ( event, editor ) => {
									const data = editor.getData();
									this.setState({ad_description: data});
									editor.ui.view.editable.element.style.minHeight = "300px";
									
								} }
							/>
							<div className="errorad_description"></div>
						</div>
						
					</div>		

					<div className="form-right">

						{(selectedCatevalue=='range'||selectedCatevalue=='both')&&<><div className="form-group">
							<label>From Range ($) <span class="required" style={{ color: "red" }} >*</span>:</label>
							<input type="number" name="range" onChange={this.handleInputChange} className="form-control"  min="1" value={this.state.range} />
							<div className="errorrange"></div>
						</div>	
						<div className="form-group">
							<label>To Range ($) <span class="required" style={{ color: "red" }} >*</span>:</label>
							<input type="number" name="to_range" onChange={this.handleInputChange} className="form-control"  min="1" value={this.state.to_range} />
							<div className="errorto_range"></div>
						</div></>}

						{(selectedCatevalue=='both'||selectedCatevalue=='count')&&<><div className="form-group">
							<label>Number of start count <span class="required" style={{ color: "red" }} >*</span>:</label>
								<input type="number" min="1" name="count" onChange={this.handleInputChange} className="form-control" value={this.state.count} />
							<div className="errorcount"></div>
						</div>
						<div className="form-group">
							<label>Number of end count <span class="required" style={{ color: "red" }} >*</span>:</label>
								<input type="number" min="1" name="to_count" onChange={this.handleInputChange} className="form-control" value={this.state.to_count} />
							<div className="errorto_count"></div>
						</div>
						</>}

						<div className="form-group">
							<label>Images (jpeg,jpg,png,svg)</label>
							<div className="choose-file">
							{this.state.isHidden && <div className="image_success"></div>}
							<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
							</div>
							{preview}
							{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
							<div className="errorimage"></div>  
						</div>
						
						<div className="form-group">					
							<label>Status:</label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							onChange={this.handleChange.bind(this, 'status')}
							/>
						</div>
					</div>	
				</div>		

				<div className="btn-group export">	
					<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&	<span className="load-data"></span> }Submit </button>
				</div>
			</form>
			</div>
		</div>	
	</div>
    </div>
    );
  }
}


export default (withRouter(Edit));
