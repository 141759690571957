import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";

import CKEditor from 'ckeditor4-react';
import MyEditor from './MyEditor';

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Add extends Component {
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			email_name:'',
			email_content:'',	
			Loading:false,
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

    onEditorChange( evt ) {
    	
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            email_content: evt
		// email_content: evt.editor.getData()
        } );
		}, 1000);
     
    }

	componentDidMount() {
		document.title = PageTitle('Template Add');		
    }



	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});


				const formPayload = this.state;
				var qs = require('qs');
			
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					email_name   : formPayload.email_name,
					email_content: formPayload.email_content,
				};
				
				axios.post(apiUrl+"template/add",qs.stringify(postObject)).then(res => {
					if(res.data.status === 'success'){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							this.setState({
								Loading:false
							});
							 setTimeout(
							 function(){							
								 $('.success_message').html('');
								 // const navigate = this.props.navigate;
								 this.props.navigate('/templates');
								}
							.bind(this),
							3000
							);
						}else{
						 this.setState({Loading:false});
							$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
				});
			
			}
	}

	validateForm() {

		const {email_name,email_content} = this.state;

		let errors = 0;
	
		if (!email_name) {
			errors++;
			$('.erroremail_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_name){
			$('.erroremail_name').html('');
		}
		
		if (!email_content) {
			errors++;
			$('.erroremail_content').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_content){
			$('.erroremail_content').html('');
		}



		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.templateadd !== this.props.templateadd){
    		if(Object.keys(Props.templateadd).length > 0){
				this.setState({Loading:false});
					if(Props.templateadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							this.setState({email_name:'',email_content:''})
							$('.success_message').html('');
							
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

  render() {

    return (
      <div className="wrapper"> 
	  <Header />
      <MenuSidebar currentpage="templates" />  
	 <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Template</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row mail-template-sec">
			<div className="form-left mail-template-inner">
		    	<div className="form-group">
					<label>Template Name:</label>
				    <input type="text" name="email_name" onChange={this.handleInputChange} className="form-control" value={this.state.email_name} autoComplete="off" />
					<div className="erroremail_name"></div>
				</div>
				<div className="form-group">
					<label>Template content:</label>
						
					{/* <CKEditor
					data={this.state.email_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					 <MyEditor initialData={this.state.email_content} onEditorChange={this.onEditorChange}> </MyEditor>

					<div className="erroremail_content"></div>
				</div>
				
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}


export default withRouter(Add);